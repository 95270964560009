import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, FieldArray } from "formik";
import {
    AutocompleteField,
    Checkbox,
    CheckboxSwitch,
    ColorPicker,
    ConditionalField,
    DatePicker,
    EmailField,
    Heading,
    IconPicker,
    NumberField,
    PasswordField,
    PhoneField,
    PriceField,
    RadioGroup,
    TextArea,
    TextField,
    TimePicker,
    UrlField,
} from ".";

import { Button } from "components/ui";
import { getInitialValues, getDefaultValues, useValidationSchema } from "./helpers";

const components = [
    { componentType: "heading", component: Heading },
    { componentType: "autocomplete", component: AutocompleteField },
    { componentType: "checkbox", component: Checkbox },
    { componentType: "checkboxSwitch", component: CheckboxSwitch },
    { componentType: "colorPicker", component: ColorPicker },
    { componentType: "datePicker", component: DatePicker },
    { componentType: "email", component: EmailField },
    { componentType: "iconPicker", component: IconPicker },
    { componentType: "number", component: NumberField },
    { componentType: "password", component: PasswordField },
    { componentType: "phone", component: PhoneField },
    { componentType: "price", component: PriceField },
    { componentType: "radioGroup", component: RadioGroup },
    { componentType: "text", component: TextField },
    { componentType: "textarea", component: TextArea },
    { componentType: "time", component: TimePicker },
    { componentType: "url", component: UrlField },
];

export const AdvancedForm = ({
    schema,
    onSubmit,
    onChange,
    initialValues,
    onClose,
    isPopUp = false,
    gridCols = "6",
    buttonClassName = "",
    buttonLabel = "Submit",
    submittingButtonLabel = "Sending...",
    cancelButtonLink = "",
    cancelButtonOnClick = "",
    cancelButtonLabel = "Cancel",
    showValidButton = false,
    ...props
}) => {
    const defaultValues = getDefaultValues(schema);
    const validationSchema = useValidationSchema(schema);

    return (
        <Formik
            initialValues={getInitialValues(defaultValues, initialValues)}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, setSubmitting, resetForm);
            }}
            validateOnMount
            {...props}
        >
            {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values, validateForm }) => {
                return (
                    <form onSubmit={handleSubmit} className="advanced-form">
                        <div className={"grid grid-cols-" + gridCols + " gap-4"}>
                            {schema.map(({ componentType, condition, ...formSchema }) => {
                                if (componentType === "array" && formSchema.schema) {
                                    const arraySchema = formSchema.schema;
                                    const arrayName = formSchema.name;

                                    return (
                                        <FieldArray
                                            key={formSchema.name}
                                            name={formSchema.name}
                                            render={(arrayHelpers) => (
                                                <div className="col-span-full">
                                                    <div className="grid grid-cols-6 gap-4">
                                                        {values[formSchema.name].map((values, index) =>
                                                            arraySchema.map(
                                                                ({ componentType, condition, ...formSchema }) => {
                                                                    if (
                                                                        !components.some(
                                                                            (component) =>
                                                                                component.componentType ===
                                                                                componentType
                                                                        )
                                                                    ) {
                                                                        return null;
                                                                    }

                                                                    const Component = components.find(
                                                                        (component) =>
                                                                            component.componentType === componentType
                                                                    ).component;

                                                                    formSchema.id =
                                                                        arrayName + "_" + index + "_" + formSchema.name;

                                                                    formSchema.name =
                                                                        arrayName +
                                                                        "[" +
                                                                        index +
                                                                        "][" +
                                                                        formSchema.name +
                                                                        "]";

                                                                    if (condition) {
                                                                        return (
                                                                            <ConditionalField
                                                                                key={formSchema.name}
                                                                                show={
                                                                                    condition.operator === "="
                                                                                        ? values[condition.key] ===
                                                                                          condition.value
                                                                                        : values[condition.key] !==
                                                                                          condition.value
                                                                                }
                                                                                onCollapse={() => {
                                                                                    setFieldValue(
                                                                                        formSchema.name,
                                                                                        defaultValues[formSchema.name]
                                                                                    );
                                                                                    setFieldTouched(
                                                                                        formSchema.name,
                                                                                        false
                                                                                    );
                                                                                }}
                                                                                onShow={() => {
                                                                                    setFieldValue(
                                                                                        formSchema.name,
                                                                                        defaultValues[formSchema.name]
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <Field
                                                                                    component={Component}
                                                                                    {...formSchema}
                                                                                />
                                                                            </ConditionalField>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <Field
                                                                            key={formSchema.id}
                                                                            component={Component}
                                                                            className={formSchema.className}
                                                                            {...formSchema}
                                                                        />
                                                                    );
                                                                }
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    );
                                } else {
                                    if (!components.some((component) => component.componentType === componentType)) {
                                        return null;
                                    }

                                    const Component = components.find(
                                        (component) => component.componentType === componentType
                                    ).component;

                                    formSchema.id = formSchema.name;

                                    if (condition) {
                                        return (
                                            <ConditionalField
                                                key={formSchema.name}
                                                show={
                                                    condition.operator === "="
                                                        ? condition.value.includes(values[condition.key]["value"])
                                                        : !condition.value.includes(values[condition.key]["value"])
                                                }
                                                onCollapse={() => {
                                                    formSchema.required = false;
                                                    setFieldValue(formSchema.name, defaultValues[formSchema.name]);
                                                    setFieldTouched(formSchema.name, false);
                                                }}
                                                onShow={() => {
                                                    setFieldValue(formSchema.name, defaultValues[formSchema.name]);
                                                }}
                                            >
                                                <Field key={formSchema.id} component={Component} {...formSchema} />
                                            </ConditionalField>
                                        );
                                    }

                                    return (
                                        <Field
                                            key={formSchema.id}
                                            component={Component}
                                            className={formSchema.className}
                                            {...formSchema}
                                        />
                                    );
                                }
                            })}

                            <Button
                                type={"submit"}
                                className={
                                    "btn-primary !col-start-1 col-span-3 " +
                                    (isPopUp ? "" : "md:col-span-2 xl:col-span-1 ") +
                                    buttonClassName
                                }
                                disabled={!isValid || isSubmitting}
                            >
                                {isSubmitting ? submittingButtonLabel : buttonLabel}
                            </Button>

                            {cancelButtonLink ? (
                                <Link
                                    to={cancelButtonLink}
                                    className={
                                        "btn btn-gray col-span-3" + (isPopUp ? "" : " md:col-span-2 xl:col-span-1")
                                    }
                                    disabled={isSubmitting}
                                >
                                    {cancelButtonLabel}
                                </Link>
                            ) : (
                                ""
                            )}

                            {cancelButtonOnClick && (
                                <Button
                                    onClick={cancelButtonOnClick}
                                    className={
                                        "btn btn-gray col-span-3" + (isPopUp ? "" : " md:col-span-2 xl:col-span-1")
                                    }
                                    disabled={isSubmitting}
                                >
                                    {cancelButtonLabel}
                                </Button>
                            )}

                            {showValidButton && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        validateForm().then((e) => console.log(e));
                                    }}
                                >
                                    Validate All
                                </button>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};
