import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import AxiosInstance from "utils/AxiosInstance";
import { Modal } from "components/ui";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { LoadingSpinner } from "components/common";

function ModalRefundPayment({ orderId, closeModal, reloadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [canRefund, setCanRefund] = useState(false);
    const [refund, setRefund] = useState({ orderId: orderId });

    const formSchema = [
        {
            name: "amount",
            label: t("Amount", { ns: "common" }),
            componentType: "price",
            required: true,
            fieldClassName: "col-span-full",
        },
    ];

    const makeValidationSchema = (canRefund) => {
        return Yup.object().shape({
            amount: Yup.number()
                .min(
                    canRefund.partialRefund.minRefundAmount,
                    t("Amount must be at least {{min}}.", { ns: "validation" })
                )
                .max(
                    canRefund.partialRefund.maxRefundAmount,
                    t("Amount must be less than or equal to {{max}}.", {
                        ns: "validation",
                        max: canRefund.partialRefund.maxRefundAmount,
                    })
                )
                .required(t("Amount is required,", { ns: "validation" })),
        });
    };

    useEffect(() => {
        AxiosInstance({
            url: "payments/can-refund",
            method: "POST",
            data: { orderId: orderId },
        })
            .then((response) => {
                setCanRefund(response.data);
                setRefund({ ...refund, amount: response.data.partialRefund.maxRefundAmount });

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [orderId]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/refund",
            method: "POST",
            data: values,
        })
            .then((response) => {
                setTimeout(() => {
                    setSubmitting(false);
                    closeModal();
                    reloadData();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Refund", { ns: "common" })} size="md">
            {isLoading ? (
                <LoadingSpinner />
            ) : canRefund.refundable && canRefund.refundable === true ? (
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={refund}
                    validationSchema={makeValidationSchema(canRefund)}
                    gridCols={2}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonOnClick={closeModal}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            ) : (
                <div>{t("This payment cannot be refunded.", { ns: "common" })}</div>
            )}
        </Modal>
    );
}

export default ModalRefundPayment;
