import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoRefreshOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { PaymentRefundStatus } from "utils/PaymentRefundStatus";
import { Badge, Button, Modal } from "components/ui";
import { LoadingSpinner } from "components/common";
import { DataGrid } from "components/grid";
import ModalRefundPayment from "./ModalRefundPayment";

function ModalRefundsPayment({ orderId, closeModal, reloadData }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null });
    const [isLoading, setLoading] = useState(true);
    const [canRefund, setCanRefund] = useState(false);
    const [refunds, setRefunds] = useState([]);

    const columns = [
        {
            field: "date",
            headerName: t("Refund date", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return (
                    <>
                        <FormatDate date={params.row.date} /> <FormatTime date={params.row.date} />
                    </>
                );
            },
        },
        {
            field: "transactionId",
            headerName: t("Transaction ID", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => {
                value = PaymentRefundStatus(value);
                return `${value.name}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.status.color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Amount", { ns: "common" }),
            width: 100,
            renderCell: (params) => {
                return <FormatPrice price={params.value} />;
            },
        },
    ];

    useEffect(() => {
        handleLoadData();
    }, [orderId]);

    const handleLoadData = () => {
        setLoading(true);

        Promise.all([
            AxiosInstance({
                url: "payments/can-refund",
                method: "POST",
                data: { orderId: orderId },
            }).then((response) => {
                setCanRefund(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),

            AxiosInstance({
                url: "payments/" + orderId + "/refunds",
                method: "GET",
            }).then((response) => {
                setRefunds(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
        handleLoadData();
    };

    return (
        <>
            <Modal handleClose={closeModal} title={t("Refunds", { ns: "common" })} size="lg">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {canRefund && (
                            <div className="flex justify-end mb-4">
                                <Button
                                    className={"btn-primary btn-sm"}
                                    onClick={() => {
                                        openModal(orderId, "refundPayment");
                                    }}
                                >
                                    <IoRefreshOutline size={"1.25em"} />
                                    {t("Refund", { ns: "button" })}
                                </Button>
                            </div>
                        )}

                        <DataGrid
                            rows={refunds}
                            columns={columns}
                            staticHeight={true}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            disableColumnSorting
                        />
                    </>
                )}
            </Modal>

            {modal.show && modal.data && modal.type === "refundPayment" && (
                <ModalRefundPayment orderId={modal.data} closeModal={handleModalClose} reloadData={handleLoadData} />
            )}
        </>
    );
}

export default ModalRefundsPayment;
