import React from "react";
import { useTranslation } from "react-i18next";

import { FileDownload } from "utils/FileDownload";

export const ActivityDocuments = ({ documents, activityId }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                    <strong>{t("Attachments", { ns: "common" })}:</strong>
                </div>
                <ul className="list-decimal pl-4">
                    {documents.map((document) => (
                        <li key={document.id} className="">
                            <FileDownload
                                slug={"activities/" + activityId + "/documents/" + document.id}
                                filename={document.filename}
                                mimeType={document.mimeType}
                                className={"flex-1 !justify-start !py-1 !px-2 underline"}
                            >
                                <span>{document.name}</span>
                            </FileDownload>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="text-center">
                {t("By enrolling and paying, you acknowledge that you have read and agree the above attachments.", {
                    ns: "common",
                })}
            </div>
        </>
    );
};
