import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

export const Modal = ({ open = true, handleClose, children, title = null, size = "sm", hideCloseButton = false }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            maxWidth={size}
            fullWidth={true}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            {title && (
                <DialogTitle id="scroll-dialog-title">
                    <div className="flex items-center justify-between w-full">
                        {title}
                        {hideCloseButton === false && (
                            <IconButton onClick={handleClose} className="!-mr-3">
                                <IoCloseOutline size={"1.5rem"} />
                            </IconButton>
                        )}
                    </div>
                </DialogTitle>
            )}
            <DialogContent
                sx={{
                    padding: { xs: "1rem", md: "1.5rem" },
                    paddingBottom: "1.5rem",
                }}
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};
