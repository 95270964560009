import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { Modal } from "components/ui";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { LoadingSpinner } from "components/common";

function ModalEditPayment({ orderId, closeModal, reloadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [payment, setPayment] = useState(true);

    const formSchema = [
        {
            name: "items",
            componentType: "array",
            schema: [
                {
                    name: "name",
                    label: t("Name", { ns: "common" }),
                    componentType: "text",
                    required: false,
                    readonly: "",
                    fieldClassName: "col-span-3 md:col-span-5 opacity-60",
                },
                {
                    name: "unitPrice",
                    label: t("Price", { ns: "common" }),
                    componentType: "price",
                    required: true,
                    fieldClassName: "col-span-3 md:col-span-1",
                },
            ],
        },
    ];

    useEffect(() => {
        AxiosInstance({
            url: "payments/" + orderId,
            method: "GET",
        })
            .then((response) => {
                setPayment(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [orderId]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/update-amount",
            method: "POST",
            data: values,
        })
            .then((response) => {
                setTimeout(() => {
                    setSubmitting(false);
                    closeModal();
                    reloadData();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Update amounts in payments", { ns: "common" })} size="md">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={payment}
                    gridCols={2}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonOnClick={closeModal}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            )}
        </Modal>
    );
}

export default ModalEditPayment;
