export function PaymentRefundStatus(status) {
    let color = "";
    let name = status.name.startsWith("refundStatus.") ? status.name : "refundStatus." + status.name;

    switch (status.value) {
        case "PENDING":
            color = "#313333";
            break;
        case "COMPLETED":
            color = "#166534";
            break;
        case "CANCELLED":
            color = "#313333";
            break;
        case "FAILED":
            color = "#dc2626";
            break;
        default:
            color = "#dc2626";
            break;
    }

    status.name = name;
    status.color = color;

    return status;
}
