import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    IoAccessibilityOutline,
    IoAddOutline,
    IoCalendarOutline,
    IoCreateOutline,
    IoLockOpenOutline,
    IoPeopleOutline,
    IoTrashOutline,
} from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Avatar, Box, CallTo, ContentHeading, MailTo, OptionMenu, RoleBadge } from "components/ui";
import TrainerModalActivity from "./TrainerModalActivity";
import TrainerModalDelete from "./TrainerModalDelete";
import TrainerModalRole from "./TrainerModalRole";
import TrainerModalWorkSchedule from "./TrainerModalWorkSchedule";

function TrainerList({ brandId }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [isLoading, setLoading] = useState(true);
    const [trainers, setTrainers] = useState([]);

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        AxiosInstance({
            url: "brands/" + brandId + "/trainers",
            method: "GET",
        })
            .then((response) => {
                setTrainers(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const openModal = (trainerId, type = "view") => {
        setModal({ show: true, data: { brandId: brandId, trainerId: trainerId }, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <div className="flex flex-col gap-6 trainers">
                <ContentHeading
                    showBtn={true}
                    btnUrl={"/brand/brands/" + brandId + "/trainers/new"}
                    btnIcon={<IoAddOutline size={"1.125em"} />}
                    btnLabel={t("Add trainer", { ns: "button" })}
                >
                    <IoPeopleOutline size={"1.25em"} />
                    {t("Trainers", { ns: "common" })}
                </ContentHeading>

                {isLoading ? (
                    <LoadingSpinner />
                ) : trainers.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6">
                        {trainers.map((trainer, index) => {
                            let iconButtons = [
                                {
                                    icon: <IoCreateOutline size={"1.25rem"} />,
                                    tooltipProps: {
                                        title: t("Edit", { ns: "button" }),
                                    },
                                    menuItemProps: {
                                        onClick: () =>
                                            navigate("/brand/brands/" + brandId + "/trainers/" + trainer.id + "/edit"),
                                    },
                                },
                            ];

                            let options = [
                                {
                                    text: t("Activities", { ns: "button" }),
                                    icon: <IoAccessibilityOutline size={"1.25em"} />,
                                    menuItemProps: {
                                        onClick: () => openModal(trainer.id, "activities"),
                                    },
                                },
                                {
                                    text: t("User roles", { ns: "button" }),
                                    icon: <IoLockOpenOutline size={"1.25em"} />,
                                    menuItemProps: {
                                        onClick: () => openModal(trainer.id, "role"),
                                    },
                                },
                                {
                                    text: t("Work schedule", { ns: "button" }),
                                    icon: <IoCalendarOutline size={"1.25em"} />,
                                    menuItemProps: {
                                        onClick: () => openModal(trainer.id, "workSchedule"),
                                    },
                                },
                                {
                                    text: t("Remove trainer", { ns: "button" }),
                                    icon: <IoTrashOutline size={"1.25em"} />,
                                    menuItemProps: {
                                        onClick: () => openModal(trainer.id, "removeTrainer"),
                                    },
                                },
                            ];

                            return (
                                <Box className={"box-items relative"} key={index}>
                                    <div className="flex gap-2 absolute top-4 right-4">
                                        <OptionMenu iconButtons={iconButtons} options={options} />
                                    </div>
                                    <div className="flex justify-center">
                                        <Avatar avatar={trainer.avatar} size={80} />
                                    </div>
                                    <div className="text-center">
                                        <h3>{trainer.fullName}</h3>
                                        <span className="text-sm">
                                            {trainer.activities.map((activity) => activity.name).join(", ")}
                                        </span>
                                    </div>
                                    <div className="flex justify-center gap-2">
                                        {trainer.roles.map((role, index) => (
                                            <RoleBadge role={role} key={index} />
                                        ))}
                                    </div>
                                    <div className="flex gap-2">
                                        <strong>{t("E-mail", { ns: "common" })}:</strong>
                                        <MailTo email={trainer.email}>{trainer.email}</MailTo>
                                    </div>
                                    <div className="flex gap-2">
                                        <strong>{t("Phone", { ns: "common" })}:</strong>
                                        <CallTo phone={trainer.phone}>{trainer.phone}</CallTo>
                                    </div>
                                </Box>
                            );
                        })}
                    </div>
                ) : (
                    t("No trainers found", { ns: "common" })
                )}
            </div>

            {modal.show && modal.data && modal.type === "activities" && (
                <TrainerModalActivity data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "role" && (
                <TrainerModalRole data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "workSchedule" && (
                <TrainerModalWorkSchedule data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "removeTrainer" && (
                <TrainerModalDelete data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}
        </>
    );
}

export default TrainerList;
