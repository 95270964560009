import React from "react";
import { NumericFormat } from "react-number-format";
import { FormField } from "./FormField";

export const NumberField = ({
    id,
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    validation,
    handleBlur,
    tooltipTitle,
    ...props
}) => {
    const handleValueChange = (values) => {
        // form.setFieldValue(name, values.floatValue);
        form.setFieldValue(name, values.value, true);
    };

    return (
        <FormField
            id={name}
            label={label}
            required={required}
            className={fieldClassName}
            formProps={form}
            tooltipTitle={tooltipTitle}
        >
            <NumericFormat
                onBlur={handleBlur}
                className={"form-input " + className}
                id={name}
                name={name}
                value={value}
                required={required}
                onValueChange={handleValueChange}
                {...fieldProps}
                {...props}
            />
        </FormField>
    );
};
