import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@mui/base/Dropdown";
import {
    IoAccessibilityOutline,
    IoEllipsisVerticalOutline,
    IoEyeOutline,
    IoSearchOutline,
    IoWalletOutline,
} from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { DataGrid } from "components/grid";
import { SearchForm } from "components/activitySearch";
import {
    Badge,
    Button,
    ContentHeading,
    DropdownButton,
    DropdownMenu,
    DropdownMenuItem,
    OptionMenu,
} from "components/ui";
import { DataEmpty, LoadingSpinner } from "components/common";
import ActivityModalView from "./ActivityModalView";
import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import ActivityModalEnroll from "./ActivityModalEnroll";
import CurrentUser from "services/CurrentUser";
import ModalActivityPayments from "./ModalActivityPayments";
import { EnrollmentStatus } from "utils/EnrollmentStatus";

function ActivitiesList({ kids }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [activeSearch, setActiveSearch] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [kid, setKid] = useState(kids[0]);
    const [activities, setActivities] = useState(null);

    const columns = [
        {
            field: "activity",
            headerName: t("Activity", { ns: "common" }),
            width: 320,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            valueFormatter: (value) => `${t(value, { ns: "lexicons" })}`,
            valueGetter: (value, row) => {
                value = EnrollmentStatus(value);
                return `${value.name}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.status.color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "when",
            headerName: t("When", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return params.row.activity.period.value === "SINGLE" ? (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDateTime date={d.day} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                ) : (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDayTime day={d.day.name} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                );
            },
        },
        {
            field: "where",
            headerName: t("Where", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            valueGetter: (value, row) => `${(row.type && t(row.type.name, { ns: "lexicons" })) || "---"}`,
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                let iconButtons = [
                    {
                        icon: <IoEyeOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("View details", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal(params.row, "view"),
                        },
                    },
                ];

                params.row.status.value === "OPEN" &&
                    iconButtons.push({
                        icon: <IoAccessibilityOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("Enroll your kid in activity", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal({ id: params.row.id, enrollment: params.row, kid: kid }, "enroll"),
                        },
                    });

                let options = [];
                currentUser.roles.includes("ROLE_PARENT") &&
                    params.row.billing.id &&
                    options.push({
                        text: t("Payments", { ns: "button" }),
                        icon: <IoWalletOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row.billing, "payments"),
                        },
                    });

                return <OptionMenu iconButtons={iconButtons} options={options} />;
            },
        },
    ];

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    useEffect(() => {
        if (kids.length) {
            handleLoadData();
        } else {
            setLoading(false);
        }
    }, [kid]);

    const handleLoadData = () => {
        setLoading(true);

        AxiosInstance({
            url: "kids/" + kid.id + "/activities",
            method: "GET",
        })
            .then((response) => {
                setActivities(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoAccessibilityOutline size={"1.5rem"} />
                {t("Kid activities", { ns: "common" })}
            </ContentHeading>

            {kid && (
                <div className="flex flex-col md:flex-row gap-2">
                    {kids.length > 1 && (
                        <div className="content-links">
                            {kids.map((k, index) => (
                                <Button
                                    className={k.id === kid.id ? "btn-primary" : "btn-dark-outline btn-opacity"}
                                    onClick={() => {
                                        setKid(k);
                                        setActiveSearch(false);
                                    }}
                                    key={index}
                                >
                                    {k.fullName}
                                </Button>
                            ))}
                        </div>
                    )}

                    <div className="flex-grow"></div>
                    <Button className={"btn-primary"} onClick={() => setActiveSearch(true)}>
                        <IoSearchOutline size={"1.125rem"} />
                        {t("Find new activities", { ns: "common" })}
                    </Button>
                </div>
            )}

            {isLoading ? (
                <LoadingSpinner />
            ) : kid ? (
                activeSearch ? (
                    <SearchForm data={{ kid: kid }} setActiveSearch={setActiveSearch} />
                ) : activities ? (
                    <DataGrid rows={activities} columns={columns} openModal={openModal} extraMinusHeight="-42px" />
                ) : (
                    <DataEmpty title={t("Kid is not enrolled in any activities.", { ns: "common" })} />
                )
            ) : (
                <DataEmpty title={t("You don't have any kids in the system.", { ns: "common" })} />
            )}

            {modal.show && modal.data && modal.type === "view" && (
                <ActivityModalView data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "enroll" && (
                <ActivityModalEnroll data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "payments" && (
                <ModalActivityPayments billing={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default ActivitiesList;
