import React from "react";
import { NumericFormat } from "react-number-format";
import { FormField } from "./FormField";

export const PriceField = ({
    id,
    label,
    className = "",
    fieldClassName,
    field: { name, value, ...fieldProps },
    form,
    required,
    handleBlur,
    ...props
}) => {
    const handleValueChange = (values) => {
        form.setFieldValue(name, values.value * 100, true);
    };

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <NumericFormat
                onBlur={handleBlur}
                className={"form-input " + className}
                id={name}
                name={name}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={handleValueChange}
                {...fieldProps}
                {...props}
            />
        </FormField>
    );
};
