import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoCashOutline, IoCreateOutline, IoRefreshOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Badge, Modal, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import ModalPaymentCompleted from "./ModalPaymentCompleted";
import { PaymentStatus } from "utils/PaymentStatus";
import ModalEditPayment from "../../../components/modal/ModalEditPayment";
import ModalRefundsPayment from "components/modal/ModalRefundsPayment";

function ModalBillingPayments({ billing, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [modal, setModal] = useState({ show: false, data: null });

    const columns = [
        {
            field: "paymentId",
            headerName: t("Payment ID", { ns: "common" }),
            width: 120,
            valueGetter: (value, row) => `${value || "---"}`,
        },
        {
            field: "dueDate",
            headerName: t("Due date", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return <FormatDate date={params.row.dueDate} />;
            },
        },
        {
            field: "paymentDate",
            headerName: t("Payment date", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return <FormatDate date={params.row.paymentDate} />;
            },
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 140,
            valueGetter: (value, row) => {
                let status = PaymentStatus(row.status.value, row.dueDate, row.required);
                row.color = status.color;

                return `${status.title}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.color}>{t(params.value, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "period",
            headerName: t("Period", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <FormatDate date={params.row.period.start} />
                        <span> - </span>
                        <FormatDate date={params.row.period.end} />
                    </>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Amount", { ns: "common" }),
            width: 100,
            renderCell: (params) => {
                return <FormatPrice price={params.row.amount} />;
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 80,
            renderCell: (params) => {
                let options = [];

                if (
                    params.row.status.value === "NEW" ||
                    params.row.status.value === "CANCELLED" ||
                    params.row.status.value === "REJECTED" ||
                    params.row.status.value === "FAILED"
                ) {
                    options.push({
                        text: t("Edit payment", { ns: "button" }),
                        icon: <IoCreateOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row.orderId, "editPayment"),
                        },
                    });
                }

                params.row.status.value === "NEW" &&
                    options.push({
                        text: t("Invoice paid", { ns: "button" }),
                        icon: <IoCashOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row.orderId, "paymentCompleted"),
                        },
                    });

                if (
                    params.row.status.value === "COMPLETED" ||
                    params.row.status.value === "REFUNDED" ||
                    params.row.status.value === "PARTIAL_REFUNDED"
                ) {
                    options.push({
                        text: t("Refunds", { ns: "button" }),
                        icon: <IoRefreshOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row.orderId, "refundsPayment"),
                        },
                    });
                }

                params.row.invoices.length > 0 &&
                    options.push({
                        pdf: 1,
                        props: {
                            invoiceSlug: params.row.invoices[0].slug,
                            invoiceFileName: params.row.invoices[0].fileName,
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {
        handleLoadData();
    }, [billing]);

    const handleLoadData = () => {
        setLoading(true);

        AxiosInstance({
            url: "billings/" + billing.id + "/payments",
            method: "GET",
        })
            .then((response) => {
                setPayments(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <Modal handleClose={closeModal} title={t("Information about payments", { ns: "common" })} size={"lg"}>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <DataGrid
                        rows={payments}
                        columns={columns}
                        staticHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        disableColumnSorting
                    />
                )}
            </Modal>

            {modal.show && modal.data && modal.type === "paymentCompleted" && (
                <ModalPaymentCompleted orderId={modal.data} closeModal={handleModalClose} reloadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "editPayment" && (
                <ModalEditPayment orderId={modal.data} closeModal={handleModalClose} reloadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "refundsPayment" && (
                <ModalRefundsPayment orderId={modal.data} closeModal={handleModalClose} reloadData={handleLoadData} />
            )}
        </>
    );
}

export default ModalBillingPayments;
