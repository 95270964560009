import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { Button } from "components/ui";

export const FileDownload = (props) => {
    const { t } = useTranslation();

    const { key, slug, filename, mimeType, className, children } = props;

    const downloadPDF = async () => {
        try {
            const { data } = await AxiosInstance.get(slug, {
                cache: false,
                responseType: "blob",
            });

            const blob = new Blob([data], { type: mimeType });
            const url = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute("download", filename); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            const errorObj = JSON.parse(await error.response.data.text());

            enqueueSnackbar(t(errorObj.message, { ns: "server" }), {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
        }
    };

    return (
        <Button onClick={downloadPDF} className={className} key={key}>
            {children}
        </Button>
    );
};
