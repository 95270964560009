import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { AdvancedForm } from "components/forms/AdvancedForm";
import { Box, ContentHeading } from "components/ui";

function KidForm({ kid, cancelButtonLink = "/parent/kids" }) {
    const { t } = useTranslation();

    const [dietOptions, setDietOptions] = useState([]);
    const [allergyOptions, setAllergyOptions] = useState([]);

    const formSchema = [
        {
            name: "heading1",
            componentType: "heading",
            label: t("Background information", { ns: "common" }),
            fieldClassName: "col-span-full",
        },
        {
            name: "firstName",
            label: t("First name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            name: "lastName",
            label: t("Last name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-2",
        },
        {
            name: "birthday",
            label: t("Birthday", { ns: "common" }),
            componentType: "datePicker",
            required: true,
            fieldClassName: "col-span-full md:col-span-2",
            maxDate: dayjs(),
            openTo: "year",
        },
        {
            name: "street",
            label: t("Street", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-4",
        },
        {
            name: "number",
            label: t("Number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-3 md:col-span-1",
        },
        {
            name: "premises",
            label: t("Premises number", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-3 md:col-span-1",
        },
        {
            name: "zipCode",
            label: t("Zip code", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 20,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "city",
            label: t("City", { ns: "common" }),
            componentType: "text",
            required: false,
            maxLength: 255,
            fieldClassName: "col-span-full md:col-span-3",
        },
        {
            name: "heading2",
            componentType: "heading",
            label: t("Health information", { ns: "common" }),
            fieldClassName: "col-span-full",
        },
        {
            name: "diet",
            label: t("Diet", { ns: "common" }),
            componentType: "autocomplete",
            required: false,
            fieldClassName: "col-span-full md:col-span-2",
            options: dietOptions,
            getOptionLabel: (option) => t(option.name, { ns: "common" }),
        },
        {
            name: "allergies",
            label: t("Allergy", { ns: "common" }),
            componentType: "autocomplete",
            required: false,
            isMulti: true,
            fieldClassName: "col-span-full md:col-span-4",
            options: allergyOptions,
            getOptionLabel: (option) => t(option.name, { ns: "common" }),
        },
        {
            name: "heading3",
            componentType: "heading",
            label: t("Additional information", { ns: "common" }),
            fieldClassName: "col-span-full",
        },
        {
            name: "photoConsent",
            label: t("Consent for photos of the kid", { ns: "common" }) + ":",
            componentType: "checkboxSwitch",
            fieldClassName: "col-span-full",
        },
        {
            name: "color",
            label: t("Color", { ns: "common" }),
            componentType: "colorPicker",
            required: true,
            maxLength: 180,
            fieldClassName: "col-span-full md:col-span-3 xl:col-span-2",
        },
        {
            name: "information",
            label: t("Additional information", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
    ];

    useEffect(() => {
        AxiosInstance({
            url: "diets",
            method: "GET",
        }).then((response) => {
            setDietOptions(response.data);
        });

        AxiosInstance({
            url: "allergies",
            method: "GET",
        }).then((response) => {
            setAllergyOptions(response.data);
        });
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "kids";
        let method = "POST";

        if (kid) {
            url += "/" + kid.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!kid && response.status === 201) {
                    resetForm({ values: "" });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <>
            <ContentHeading tag="h3">
                {kid ? t("Edit kid", { ns: "common" }) : t("Add kid", { ns: "common" })}
            </ContentHeading>

            <Box>
                <AdvancedForm
                    schema={formSchema}
                    onSubmit={handleSubmit}
                    initialValues={kid}
                    buttonLabel={t("Save", { ns: "button" })}
                    submittingButtonLabel={t("Saving", { ns: "button" })}
                    cancelButtonLink={cancelButtonLink}
                    cancelButtonLabel={t("Cancel", { ns: "button" })}
                />
            </Box>
        </>
    );
}

export default KidForm;
