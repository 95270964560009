import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import AxiosInstance from "utils/AxiosInstance";
import CurrentUser from "services/CurrentUser";
import { useLexicons } from "hooks/useLexicons";
import { FormatDate, FormatPrice, FormatTime } from "utils/Format";
import { Button, Modal } from "components/ui";
import { AutocompleteField } from "components/forms";
import { SecureForm } from "components/PayU/SecureForm";
import { ActivityDocuments } from "components/activitySearch/ActivityDocuments";
import { LoadingSpinner } from "components/common";

function ActivityModalEnroll({ data, closeModal }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lexicons = useLexicons();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);

    const [card, setCard] = useState(null);
    const [enrollmentWhoPayOptions, setEnrollmentWhoPayOptions] = useState([]);
    const [paymentFrequencyOptions, setPaymentFrequencyOptions] = useState([]);
    const [availableDatesOptions, setAvailableDatesOptions] = useState([]);
    const [enrolled, setEnrolled] = useState(false);
    const [enrolledMessage, setEnrolledMessage] = useState(null);

    const [activityDocuments, setActivityDocuments] = useState([]);

    const defaultValues = {
        paymentFrequency: null,
        card: card,
        ...data,
    };

    const validationSchema = Yup.object().shape({
        // paymentFrequency: Yup.object().required(t("This field is required.", { ns: "validation" })),
        // paymentFrequency: Yup.object().when("period", {
        //     is: (period) => period && period.value === "CYCLICAL",
        //     then: () =>
        //         Yup.object().required(t("This field is required.", { ns: "validation" })),
        //     otherwise: () => Yup.array(),
        // }),
    });

    useEffect(() => {
        if (lexicons) {
            setEnrollmentWhoPayOptions(lexicons["enrollment"]["whoPay"]);
            setPaymentFrequencyOptions(lexicons["payment"]["frequency"]);
        }

        Promise.all([
            AxiosInstance({
                url: "activities/" + data.enrollment.activity.id + "/documents",
                method: "GET",
            }).then((response) => {
                setActivityDocuments(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [data, lexicons]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/enroll-kid",
            method: "POST",
            data: values,
        })
            .then((response) => {
                if (response.data.data.orderId) {
                    setEnrolled(true);
                    setEnrolledMessage(response.data.message);
                }
                // if (response.data.data.orderID && response.data.data.formContext) {
                //     sessionStorage.setItem("payment_" + response.data.data.orderID, JSON.stringify(response.data.data));

                //     setEnrolled(true);
                //     setEnrolledMessage(response.data.message);
                // }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);

                setTimeout(() => {
                    if (response.data.data.orderId) {
                        navigate("/payment/" + response.data.data.orderId);
                    }
                }, 1000);
            })
            .catch((error) => {
                if (error.response.data.message && error.response.data.message.indexOf("Invalid token")) {
                    setCard(null);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    const handleSaveCard = (card) => {
        setCard(card);
    };

    return (
        <Modal handleClose={closeModal} title={t("Enroll in an activity", { ns: "common" })}>
            {enrolled ? (
                <>
                    <h3>{t(enrolledMessage, { ns: "common" })}</h3>
                </>
            ) : isLoading ? (
                <LoadingSpinner />
            ) : data.enrollment ? (
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-2">
                                        {data.kid && (
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Name of the kid", { ns: "common" })}:</strong>
                                                {data.kid.fullName}
                                            </div>
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity", { ns: "common" })}:</strong>
                                            {data.enrollment.activity.name}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Description", { ns: "common" })}:</strong>
                                            {data.enrollment.activity.description}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Brand", { ns: "common" })}:</strong>
                                            {data.enrollment.activity.brand.name}
                                        </div>
                                        {data.enrollment.activity.period.value === "SINGLE" && (
                                            <>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Date", { ns: "common" })}:</strong>
                                                    <FormatDate date={data.enrollment.date[0].day} />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Hour", { ns: "common" })}:</strong>
                                                    <FormatTime
                                                        date={data.enrollment.date[0].time}
                                                        formatting={{ hour: "numeric", minute: "numeric" }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Type of activity", { ns: "common" })}:</strong>
                                            {t(data.enrollment.activity.period.name, { ns: "lexicons" })}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Price per activity", { ns: "common" })}:</strong>
                                            <FormatPrice price={data.enrollment.billing.price} />
                                        </div>
                                        {data.enrollment.activity.period.value === "CYCLICAL" && (
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Due date", { ns: "common" })}:</strong>
                                                {t("by the {{day}} of the month", {
                                                    ns: "common",
                                                    day: t("{{count}}", {
                                                        count: data.enrollment.paymentTerm,
                                                        ordinal: true,
                                                    }),
                                                })}
                                            </div>
                                        )}
                                    </div>

                                    {(currentUser != null && currentUser.accountType === "parent") ?? (
                                        <>
                                            {data.enrollment.activity.period.value === "CYCLICAL" && card === null && (
                                                <>
                                                    <div className="border-t"></div>
                                                    <SecureForm handleSaveCard={handleSaveCard} />
                                                </>
                                            )}
                                        </>
                                    )}

                                    {data.enrollment.activity.period.value === "CYCLICAL" && (
                                        <>
                                            <div className="border-t"></div>
                                            <Field
                                                component={AutocompleteField}
                                                key={"paymentFrequency"}
                                                name={"paymentFrequency"}
                                                label={t("Frequency of payment", { ns: "common" })}
                                                required={true}
                                                fieldClassName={"col-span-2"}
                                                options={paymentFrequencyOptions}
                                                getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                            />
                                        </>
                                    )}

                                    {data.enrollment.billing.id && (
                                        <div className="flex flex-col text-center">
                                            <strong>
                                                {t(
                                                    "Your kid has attended activities and will be re-enrolled in activity.",
                                                    { ns: "common" }
                                                )}
                                            </strong>
                                            {data.enrollment.billing.debt > 0 && (
                                                <strong>
                                                    {t(
                                                        "You are in arrears from previous classes in the amount of {{price, currency(currency)}}. The amount will be added to your first payment.",
                                                        {
                                                            ns: "common",
                                                            price: data.enrollment.billing.debt,
                                                            currency: data.enrollment.billing.currency,
                                                        }
                                                    )}
                                                </strong>
                                            )}
                                        </div>
                                    )}

                                    {activityDocuments.length > 0 && (
                                        <ActivityDocuments
                                            documents={activityDocuments}
                                            activityId={data.enrollment.activity.id}
                                        />
                                    )}

                                    <div className="flex flex-row justify-center">
                                        {t("Do you want to enroll in a activity?", { ns: "common" })}
                                    </div>

                                    <div className="flex flex-row justify-center gap-2">
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary"}
                                            onClick={handleSubmit}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Yes", { ns: "button" })}
                                        </Button>
                                        <Button className={"btn-gray"} onClick={closeModal} disabled={isSubmitting}>
                                            {t("No", { ns: "button" })}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ActivityModalEnroll;
