import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { FormatDate, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";
import { AutocompleteField } from "components/forms";

function ActivityModalGroup({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [groupsOptions, setGroupsOptions] = useState([]);
    const [enrollment, setEnrollment] = useState(null);
    const [enrollmentGroups, setEnrollmentGroups] = useState(null);

    const validationSchema = Yup.object().shape({
        // activityGroup: Yup.array().required(t("This field is required.", { ns: "validation" })),
    });

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "enrollments/" + data.id,
                method: "GET",
            }).then((response) => {
                setEnrollment(response.data);
            }),

            AxiosInstance({
                url: "enrollments/" + data.id + "/groups",
                method: "GET",
            }).then((response) => {
                setEnrollmentGroups(response.data);
            }),

            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/groups",
                method: "GET",
            }).then((response) => {
                setGroupsOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [currentUser, data]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/" + data.id + "/groups",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                handleLoadData();

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Select groups for activities", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : enrollment ? (
                <Formik
                    initialValues={enrollmentGroups}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity", { ns: "common" })}:</strong>
                                            {enrollment.activity.name}
                                        </div>
                                        {enrollment.activity.period.value === "SINGLE" ? (
                                            <>
                                                {enrollment.date.map((d) => {
                                                    return (
                                                        <>
                                                            <div className="flex items-center gap-2">
                                                                <strong>{t("Date", { ns: "common" })}:</strong>
                                                                <FormatDate date={d.day} />
                                                            </div>
                                                            <div className="flex items-center gap-2">
                                                                <strong>{t("Hour", { ns: "common" })}:</strong>
                                                                <FormatTime
                                                                    date={d.time}
                                                                    formatting={{ hour: "numeric", minute: "numeric" }}
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Dates", { ns: "common" })}:</strong>
                                                {enrollment.date.map((d, index) => (
                                                    <div key={index}>
                                                        <DisplayDayTime day={d.day.name} time={d.time} />
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Type of activity", { ns: "common" })}:</strong>
                                            {t(enrollment.activity.period.name, { ns: "lexicons" })}
                                        </div>
                                        {enrollment.whoPay && (
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Who pay", { ns: "common" })}:</strong>
                                                {t(enrollment.whoPay.name, { ns: "lexicons" })}
                                            </div>
                                        )}
                                    </div>

                                    <div className="border-t"></div>

                                    <div className="flex flex-col gap-2">
                                        <FieldArray
                                            name="test"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {enrollment.date.map((d, index) => (
                                                        <Field
                                                            key={index}
                                                            component={AutocompleteField}
                                                            isMulti={true}
                                                            name={`${index}.groups`}
                                                            label={
                                                                enrollment.activity.period.value === "SINGLE" ? (
                                                                    <DisplayDateTime date={d.day} time={d.time} />
                                                                ) : (
                                                                    <DisplayDayTime day={d.day.name} time={d.time} />
                                                                )
                                                            }
                                                            required={true}
                                                            fieldClassName={"col-span-2"}
                                                            options={groupsOptions}
                                                        />
                                                    ))}
                                                </>
                                            )}
                                        />
                                    </div>

                                    <div className="flex flex-row justify-center gap-2">
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary"}
                                            onClick={handleSubmit}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
}

export default ActivityModalGroup;
