import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import { Box, Button, ContentHeading } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import {
    TextArea,
    TextField,
    AutocompleteField,
    PriceField,
    DatePicker,
    TimePicker,
    NumberField,
    YesNoField,
    SelectField,
} from "components/forms";
import { Checkbox, FormControlLabel } from "@mui/material";
import { LoadingSpinner } from "components/common";
import { useLexicons } from "hooks/useLexicons";

function ActivityForm({ brandId, activity }) {
    const { t } = useTranslation(["validation"]);
    const lexicons = useLexicons();

    const [isLoading, setLoading] = useState(true);
    const [activityCategoriesOptions, setActivityCategoriesOptions] = useState([]);
    const [activityTypesOptions, setActivityTypesOptions] = useState([]);
    const [activityCityOptions, setActivityCityOptions] = useState([]);
    const [activityForWhomOptions, setActivityForWhomOptions] = useState([]);
    const [activityPeriodOptions, setActivityPeriodOptions] = useState([]);
    const [activityFrequencyOptions, setActivityFrequencyOptions] = useState([]);
    const [activityPaymentFormOptions, setActivityPaymentFormOptions] = useState([]);

    const defaultValues = {
        name: "",
        category: null,
        type: null,
        city: null,
        period: null,
        frequency: null,
        date: [
            {
                day: null,
                time: null,
            },
        ],
        forWhom: "",
        billingMethods: [],
        isFree: false,
        activityLength: null,
        territory: "",
        showDescription: false,
        description: "",
    };

    useEffect(() => {
        if (lexicons) {
            setActivityForWhomOptions(lexicons["activity"]["forWhom"]);
            setActivityPeriodOptions(lexicons["activity"]["period"]);
            setActivityFrequencyOptions(lexicons["activity"]["frequency"]);
            setActivityPaymentFormOptions(lexicons["activity"]["paymentForm"]);
        }

        Promise.all([
            AxiosInstance({
                url: "activity-cities",
                method: "GET",
            }).then((response) => {
                setActivityCityOptions(response.data);
            }),

            AxiosInstance({
                url: "activity-categories",
                method: "GET",
            }).then((response) => {
                setActivityCategoriesOptions(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });

        if (activity && activity.category) {
            loadTypeByCategory(activity.category.id);
        }
    }, [activity, lexicons]);

    function loadTypeByCategory(categoryId) {
        AxiosInstance({
            url: "activity-types/" + categoryId,
            method: "GET",
        }).then((response) => {
            setActivityTypesOptions(response.data);
        });
    }

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "brands/" + brandId + "/activities";
        let method = "POST";

        if (activity) {
            url = "brands/" + brandId + "/activities/" + activity.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!activity && response.status === 201) {
                    resetForm({ values: defaultValues });
                }
                resetForm({ values: response.data.data });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    function getBillingMethods(forWhom, period) {
        const whoPay = lexicons["enrollment"]["whoPay"];
        let bM = [];

        if (forWhom === "PARENT") {
            lexicons["enrollment"]["billingMethod"]["PAY_PARENT"].map((billingMethod) => {
                if (period === "SINGLE" && !billingMethod.value.includes("ACTIVITY")) {
                    return null;
                }

                return bM.push({
                    billingMethod: billingMethod,
                    active: false,
                    price: "",
                    period: period,
                    minGroupSize: null,
                    maxGroupSize: null,
                    isRemoveKid: false,
                    whenRemoveKid: null,
                    paymentForm: null,
                    paymentTerm: null,
                    whoPay: whoPay.filter((wp) => {
                        return wp.value === "PAY_PARENT";
                    })[0],
                });
            });
        } else if (forWhom === "INSTITUTION") {
            for (const element in lexicons["enrollment"]["billingMethod"]) {
                lexicons["enrollment"]["billingMethod"][element].map((billingMethod) => {
                    if (period === "SINGLE" && !billingMethod.value.includes("ACTIVITY")) {
                        return null;
                    }

                    return bM.push({
                        billingMethod: billingMethod,
                        active: false,
                        price: "",
                        period: period,
                        minGroupSize: null,
                        maxGroupSize: null,
                        isRemoveKid: false,
                        whenRemoveKid: null,
                        paymentForm: null,
                        paymentTerm: null,
                        whoPay: whoPay.filter((wp) => {
                            return wp.value === element;
                        })[0],
                    });
                });
            }
        }

        return bM;
    }

    function renderBillingMethodDescription(value) {
        switch (value) {
            case "PARENT_PAY_PER_MONTH":
                return "Rodzic zapisuje dziecko na zajęcia oferowane przez placówkę. Płaci z góry ustaloną kwotę za cały miesiąc.";
            case "PARENT_PAY_PER_ACTIVITY":
                return "Rodzic zapisuje dziecko na zajęcia oferowane przez placówkę. Płaci za zajęcia z góry za cały miesiąc. System wylicza ile zajęć będzie w miesiącu i mnoży przez ustaloną cenę.";
            case "INSTITUTION_PAY_PER_MONTH":
                return "Cena z góry jest ustalona i dotyczy jednego terminu w tygodniu, jeśli użytkownik wybierze więcej terminów zajęć w tygodniu to kwota zostanie pomnożona przez ilość wybranych terminów. Faktura generowana jest na koniec miesiąca.";
            case "INSTITUTION_PAY_PER_ACTIVITY":
                return "Cena dotyczy jednych zajęć, system liczy ile zajęć odbyło się w miesiącu i mnoży to przez ustaloną cenę. Faktura generowana jest na koniec miesiąca.";
            case "INSTITUTION_PAY_PER_KID":
                return "Cena zostanie pomnożona przez ilość dzieci zapisanych na zajęcia. Faktura generowana jest na koniec miesiąca.";
            case "INSTITUTION_PAY_PER_KID_ACTIVITY":
                return "System sumuje wszystkie obecności dziecka na zajęciach w miesiącu. Faktura generowana jest na koniec miesiąca.";
            default:
                return "";
        }
    }

    function getPriceLabel(billingMethod) {
        return billingMethod === "INSTITUTION_PAY_PER_MONTH" || billingMethod === "PARENT_PAY_PER_MONTH"
            ? t("Price per month", { ns: "common" })
            : billingMethod === "INSTITUTION_PAY_PER_KID"
            ? t("Price per kid", { ns: "common" })
            : t("Price per activity", { ns: "common" });
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, t("Field too short. Minimum number of characters: {{min}}.", { ns: "validation", min: 3 }))
            .max(255, t("Field too long. Maximum number of characters: {{max}}.", { ns: "validation", max: 255 }))
            .required(t("This field is required.", { ns: "validation" })),
        category: Yup.object().required(t("This field is required.", { ns: "validation" })),
        type: Yup.object().required(t("This field is required.", { ns: "validation" })),
        city: Yup.object().required(t("This field is required.", { ns: "validation" })),
        period: Yup.object().required(t("This field is required.", { ns: "validation" })),
        frequency: Yup.object()
            .nullable()
            .when("period", {
                is: (period) => period && period.value === "CYCLICAL",
                then: () => Yup.object().required(t("This field is required.", { ns: "validation" })),
            }),
        forWhom: Yup.object().required(t("This field is required.", { ns: "validation" })),
        isFree: Yup.boolean().required(t("This field is required.", { ns: "validation" })),
        date: Yup.object().when("period", {
            is: (period) => period && period.value === "SINGLE",
            then: () =>
                Yup.array(
                    Yup.object().shape({
                        day: Yup.date().required(t("This field is required.", { ns: "validation" })),
                        time: Yup.date().required(t("This field is required.", { ns: "validation" })),
                    })
                ),
            otherwise: () => Yup.array(),
        }),
        activityLength: Yup.number()
            .integer()
            .required(t("This field is required.", { ns: "validation" })),
        territory: Yup.string().required(t("This field is required.", { ns: "validation" })),
        description: Yup.string().required(t("This field is required.", { ns: "validation" })),
        billingMethods: Yup.array().of(
            Yup.object().shape({
                price: Yup.number()
                    .nullable()
                    .when("active", {
                        is: true,
                        then: () =>
                            Yup.number()
                                .min(
                                    0.1,
                                    t("Value too low. The minimum value is {{min}}.", { ns: "validation", min: 0.1 })
                                )
                                .required(t("This field is required.", { ns: "validation" })),
                    }),
                paymentForm: Yup.object()
                    .nullable()
                    .when(["active", "whoPay"], {
                        is: (active, whoPay) => active && whoPay.value === "PAY_INSTITUTION",
                        then: () => Yup.object().required(t("This field is required.", { ns: "validation" })),
                    }),
                paymentTerm: Yup.number()
                    .nullable()
                    .when(["active", "period"], {
                        is: (active, period) => active && period === "CYCLICAL",
                        then: () =>
                            Yup.number()
                                .integer(t("The value must be an integer.", { ns: "validation" }))
                                .required(t("This field is required.", { ns: "validation" }))
                                .when("whoPay", {
                                    is: (whoPay) => whoPay && whoPay.value === "PAY_INSTITUTION",
                                    then: (schema) => {
                                        return schema
                                            .min(
                                                0,
                                                t("Value too low. The minimum value is {{min}}.", {
                                                    ns: "validation",
                                                    min: 0,
                                                })
                                            )
                                            .max(
                                                30,
                                                t("Value too high. The maximum value is {{max}}.", {
                                                    ns: "validation",
                                                    max: 30,
                                                })
                                            );
                                    },
                                    otherwise: (schema) => {
                                        return schema
                                            .min(
                                                1,
                                                t("Value too low. The minimum value is {{min}}.", {
                                                    ns: "validation",
                                                    min: 1,
                                                })
                                            )
                                            .max(
                                                28,
                                                t("Value too high. The maximum value is {{max}}.", {
                                                    ns: "validation",
                                                    max: 28,
                                                })
                                            );
                                    },
                                }),
                    }),
                minGroupSize: Yup.number()
                    .nullable()
                    .when("active", {
                        is: true,
                        then: () =>
                            Yup.number()
                                .nullable()
                                .min(
                                    1,
                                    t("Value too low. The minimum value is {{min}}.", { ns: "validation", min: 1 })
                                ),
                    }),
                maxGroupSize: Yup.number()
                    .nullable()
                    .when("active", {
                        is: true,
                        then: () =>
                            Yup.number()
                                .nullable()
                                .min(
                                    1,
                                    t("Value too low. The minimum value is {{min}}.", { ns: "validation", min: 1 })
                                ),
                    }),
                isRemoveKid: Yup.boolean().required(t("This field is required.", { ns: "validation" })),
                whenRemoveKid: Yup.number()
                    .nullable()
                    .when("isRemoveKid", {
                        is: true,
                        then: () => Yup.number().required(t("This field is required.", { ns: "validation" })),
                    }),
            })
        ),
    });

    return (
        <>
            <ContentHeading tag="h3">
                {activity ? t("Edit activity", { ns: "common" }) : t("Add activities", { ns: "common" })}
            </ContentHeading>

            <Box>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Formik
                        initialValues={getInitialValues(defaultValues, activity)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleBlur,
                            setFieldValue,
                            setFieldTouched,
                            values,
                            validateForm,
                        }) => {
                            return (
                                <Form key={"activityForm"}>
                                    <div className="grid grid-cols-6 gap-6">
                                        <Field
                                            component={TextField}
                                            key={"name"}
                                            name={"name"}
                                            label={t("Name", { ns: "common" })}
                                            required={true}
                                            maxLength={255}
                                            fieldClassName={"col-span-full"}
                                        />
                                        <Field
                                            component={AutocompleteField}
                                            key={"category"}
                                            name={"category"}
                                            label={t("Category", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityCategoriesOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "common" })}
                                            onChange={(event, value) => {
                                                // setFieldTouched("category");
                                                setFieldValue("category", value);
                                                setFieldValue("type", "");
                                                value && value.id
                                                    ? loadTypeByCategory(value.id)
                                                    : setActivityTypesOptions([]);
                                            }}
                                        />
                                        <Field
                                            component={AutocompleteField}
                                            key={"type"}
                                            name={"type"}
                                            label={t("Type", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityTypesOptions}
                                            getOptionLabel={(option) => t(option.name, { ns: "common" })}
                                        />
                                        <Field
                                            component={AutocompleteField}
                                            key={"city"}
                                            name={"city"}
                                            label={t("City", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            handleBlur={handleBlur}
                                            options={activityCityOptions}
                                        />
                                        <Field
                                            component={SelectField}
                                            key={"period"}
                                            name={"period"}
                                            label={t("Period", { ns: "common" })}
                                            required={true}
                                            handleBlur={handleBlur}
                                            fieldClassName={"col-span-full md:col-span-2"}
                                            disabled={activity && true}
                                            options={activityPeriodOptions}
                                            onChange={(event) => {
                                                setFieldValue("period", event.target);
                                                values.forWhom &&
                                                    setFieldValue(
                                                        "billingMethods",
                                                        getBillingMethods(values.forWhom.value, event.target.value)
                                                    );
                                            }}
                                        />
                                        {values.period && values.period.value === "CYCLICAL" && (
                                            <>
                                                <Field
                                                    component={SelectField}
                                                    key={"frequency"}
                                                    name={"frequency"}
                                                    label={t("Frequency of activity", { ns: "common" })}
                                                    required={true}
                                                    handleBlur={handleBlur}
                                                    fieldClassName={"col-span-full md:col-span-2"}
                                                    disabled={activity && true}
                                                    options={activityFrequencyOptions}
                                                />
                                            </>
                                        )}
                                        {values.period &&
                                            values.period.value === "SINGLE" &&
                                            values.date.map((d, i) => (
                                                <Fragment key={i}>
                                                    <Field
                                                        component={DatePicker}
                                                        key={"date.day." + i}
                                                        name={"date[" + i + "][day]"}
                                                        minDate={dayjs()}
                                                        label={t("Date of activity", { ns: "common" })}
                                                        fieldClassName={"col-span-full md:col-span-2"}
                                                    />
                                                    <Field
                                                        component={TimePicker}
                                                        key={"date.time." + i}
                                                        name={"date[" + i + "][time]"}
                                                        label={t("Hour", { ns: "common" })}
                                                        required={true}
                                                        fieldClassName={"col-span-full md:col-span-2"}
                                                    />
                                                </Fragment>
                                            ))}
                                        <Field
                                            component={SelectField}
                                            key={"forWhom"}
                                            name={"forWhom"}
                                            label={t("For whom", { ns: "common" })}
                                            required={true}
                                            handleBlur={handleBlur}
                                            fieldClassName={"!col-start-1 col-span-full md:col-span-2"}
                                            options={activityForWhomOptions}
                                            onChange={(event) => {
                                                setFieldValue("forWhom", event.target);
                                                values.period &&
                                                    setFieldValue(
                                                        "billingMethods",
                                                        getBillingMethods(event.target.value, values.period.value)
                                                    );
                                            }}
                                        />
                                        <Field
                                            component={YesNoField}
                                            key={"isFree"}
                                            name={"isFree"}
                                            label={t("Free activity", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-3 md:col-span-2"}
                                        />
                                        <Field
                                            component={NumberField}
                                            key={"activityLength"}
                                            name={"activityLength"}
                                            label={t("Activity length", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-3 md:col-span-2"}
                                        />
                                        <FieldArray
                                            name="billingMethods"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {values.isFree === false &&
                                                        values.billingMethods.map((bM, index) => (
                                                            <div className="!col-start-1 col-span-full" key={index}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(e) => {
                                                                                setFieldValue(
                                                                                    `billingMethods.${index}.active`,
                                                                                    e.target.checked
                                                                                );
                                                                            }}
                                                                            name={`billingMethods.${index}.active`}
                                                                            checked={bM.active}
                                                                            color="default"
                                                                        />
                                                                    }
                                                                    label={t("Pay {{whoPay}} - {{billingMethod}}", {
                                                                        ns: "common",
                                                                        whoPay: t(bM.whoPay.name, { ns: "lexicons" }),
                                                                        billingMethod: t(bM.billingMethod.name, {
                                                                            ns: "lexicons",
                                                                        }),
                                                                    })}
                                                                    disabled={
                                                                        bM.billingMethod.value ===
                                                                            "INSTITUTION_PAY_PER_KID_ACTIVITY" ||
                                                                        bM.billingMethod.value ===
                                                                            "INSTITUTION_PAY_PER_KID"
                                                                    }
                                                                />

                                                                <p
                                                                    className={
                                                                        "text-sm italic m-0 " +
                                                                        ((bM.billingMethod.value ===
                                                                            "INSTITUTION_PAY_PER_KID_ACTIVITY" ||
                                                                            bM.billingMethod.value ===
                                                                                "INSTITUTION_PAY_PER_KID") &&
                                                                            "opacity-40")
                                                                    }
                                                                >
                                                                    {renderBillingMethodDescription(
                                                                        bM.billingMethod.value
                                                                    )}
                                                                </p>

                                                                {bM.active === true && (
                                                                    <div className="grid grid-cols-6 gap-6 mt-6">
                                                                        <Field
                                                                            component={PriceField}
                                                                            key={`billingMethods.${index}.price`}
                                                                            name={`billingMethods.${index}.price`}
                                                                            handleBlur={handleBlur}
                                                                            required={true}
                                                                            label={getPriceLabel(
                                                                                bM.billingMethod.value
                                                                            )}
                                                                            fieldClassName={
                                                                                "col-span-full md:col-span-1"
                                                                            }
                                                                        />
                                                                        {bM.whoPay.value === "PAY_INSTITUTION" && (
                                                                            <Field
                                                                                component={SelectField}
                                                                                key={`billingMethods.${index}.paymentForm`}
                                                                                name={`billingMethods.${index}.paymentForm`}
                                                                                handleBlur={handleBlur}
                                                                                required={true}
                                                                                label={t("Payment form", {
                                                                                    ns: "common",
                                                                                })}
                                                                                fieldClassName={
                                                                                    "col-span-full md:col-span-1"
                                                                                }
                                                                                options={activityPaymentFormOptions}
                                                                                getOptionLabel={(option) =>
                                                                                    t(option.name, {
                                                                                        ns: "lexicons",
                                                                                    })
                                                                                }
                                                                            />
                                                                        )}
                                                                        {values.period.value === "CYCLICAL" && (
                                                                            <Field
                                                                                component={NumberField}
                                                                                key={`billingMethods.${index}.paymentTerm`}
                                                                                name={`billingMethods.${index}.paymentTerm`}
                                                                                handleBlur={handleBlur}
                                                                                required={true}
                                                                                label={t("Payment term", {
                                                                                    ns: "common",
                                                                                })}
                                                                                fieldClassName={
                                                                                    "col-span-full md:col-span-1"
                                                                                }
                                                                                tooltipTitle={
                                                                                    <ul className="text-sm italic list-disc ml-4">
                                                                                        {bM.whoPay.value ===
                                                                                        "PAY_INSTITUTION" ? (
                                                                                            <>
                                                                                                <li>
                                                                                                    {t(
                                                                                                        "You can set values from 0 to 30.",
                                                                                                        {
                                                                                                            ns: "common",
                                                                                                        }
                                                                                                    )}
                                                                                                </li>
                                                                                                <li>
                                                                                                    {t(
                                                                                                        "0 - invoice due date is equal to the date of invoice generation",
                                                                                                        { ns: "common" }
                                                                                                    )}
                                                                                                </li>
                                                                                                <li>
                                                                                                    {t(
                                                                                                        "1-30 - invoice payment term equals the day of invoice generation plus 1-30 days",
                                                                                                        { ns: "common" }
                                                                                                    )}
                                                                                                </li>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <li>
                                                                                                    {t(
                                                                                                        "You can set values from 1 to 28.",
                                                                                                        {
                                                                                                            ns: "common",
                                                                                                        }
                                                                                                    )}
                                                                                                </li>
                                                                                                <li>
                                                                                                    {t(
                                                                                                        "The value indicates the day of the month by which the payment for classes must be made.",
                                                                                                        { ns: "common" }
                                                                                                    )}
                                                                                                </li>
                                                                                            </>
                                                                                        )}
                                                                                    </ul>
                                                                                }
                                                                            />
                                                                        )}
                                                                        <Field
                                                                            component={NumberField}
                                                                            key={`billingMethods.${index}.minGroupSize`}
                                                                            name={`billingMethods.${index}.minGroupSize`}
                                                                            label={t("Minimum group size", {
                                                                                ns: "common",
                                                                            })}
                                                                            fieldClassName={"col-span-3 md:col-span-1"}
                                                                            decimalScale={0}
                                                                        />
                                                                        <Field
                                                                            component={NumberField}
                                                                            key={`billingMethods.${index}.maxGroupSize`}
                                                                            name={`billingMethods.${index}.maxGroupSize`}
                                                                            label={t("Maximum group size", {
                                                                                ns: "common",
                                                                            })}
                                                                            fieldClassName={"col-span-3 md:col-span-1"}
                                                                            decimalScale={0}
                                                                        />
                                                                        {bM.whoPay.value === "PAY_PARENT" && (
                                                                            <Field
                                                                                component={YesNoField}
                                                                                key={`billingMethods.${index}.isRemoveKid`}
                                                                                name={`billingMethods.${index}.isRemoveKid`}
                                                                                label={t("Remove the kid?", {
                                                                                    ns: "common",
                                                                                })}
                                                                                required={true}
                                                                                fieldClassName={
                                                                                    "col-span-3 md:col-span-1"
                                                                                }
                                                                            />
                                                                        )}
                                                                        {bM.isRemoveKid && (
                                                                            <Field
                                                                                component={SelectField}
                                                                                key={`billingMethods.${index}.whenRemoveKid`}
                                                                                name={`billingMethods.${index}.whenRemoveKid`}
                                                                                label={t("When remove the kid?", {
                                                                                    ns: "common",
                                                                                })}
                                                                                required={true}
                                                                                handleBlur={handleBlur}
                                                                                fieldClassName={
                                                                                    "col-span-3 md:col-span-1"
                                                                                }
                                                                                getOptionLabel={(option) =>
                                                                                    t(
                                                                                        "before {{activityNo}} activity after due date",
                                                                                        {
                                                                                            ns: "lexicons",
                                                                                            activityNo: option,
                                                                                        }
                                                                                    )
                                                                                }
                                                                                options={[1, 2, 3, 4, 5, 6, 7, 8]}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(
                                                                                        `billingMethods.${index}.whenRemoveKid`,
                                                                                        event.target.value
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                </>
                                            )}
                                        />
                                        <Field
                                            component={TextField}
                                            key={"territory"}
                                            name={"territory"}
                                            label={t("Territorial scope", { ns: "common" })}
                                            required={true}
                                            fieldClassName={"col-span-full"}
                                        />

                                        <div className="!col-start-1 col-span-full">
                                            <Field
                                                component={TextArea}
                                                key={"description"}
                                                name={"description"}
                                                label={t("Description", { ns: "common" })}
                                                required={true}
                                                fieldClassName={"col-span-full"}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            setFieldValue("showDescription", e.target.checked);
                                                        }}
                                                        name={"showDescription"}
                                                        checked={values.showDescription}
                                                        color="default"
                                                    />
                                                }
                                                label={t("View description in calendar events", {
                                                    ns: "common",
                                                })}
                                            />
                                        </div>

                                        <Button
                                            type={"submit"}
                                            className={
                                                "btn-primary !col-start-1 col-span-3 md:col-span-2 xl:col-span-1"
                                            }
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                        </Button>
                                        <Link
                                            to={"/brand/brands"}
                                            className={"btn btn-gray col-span-3 md:col-span-2 xl:col-span-1"}
                                            disabled={isSubmitting}
                                        >
                                            {t("Cancel", { ns: "button" })}
                                        </Link>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Box>
        </>
    );
}

export default ActivityForm;
