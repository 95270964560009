import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import CurrentUser from "services/CurrentUser";
import { useLexicons } from "hooks/useLexicons";
import { FormatDate, FormatMinutes, FormatTime } from "utils/Format";
import { Button, Modal } from "components/ui";
import { DatePicker, AutocompleteField } from "components/forms";
import { SecureForm } from "components/PayU/SecureForm";
import { ActivityDocuments } from "./ActivityDocuments";
import { LoadingSpinner } from "components/common";

const today = dayjs();

export const ModalEnroll = ({ data, closeModal }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lexicons = useLexicons();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);

    const [card, setCard] = useState(null);
    const [enrollmentWhoPayOptions, setEnrollmentWhoPayOptions] = useState([]);
    const [billingMethodOptions, setBillingMethodOptions] = useState([]);
    const [paymentFrequencyOptions, setPaymentFrequencyOptions] = useState([]);
    const [availableDatesOptions, setAvailableDatesOptions] = useState([]);
    const [enrolled, setEnrolled] = useState(false);
    const [enrolledMessage, setEnrolledMessage] = useState(null);

    const [activityDocuments, setActivityDocuments] = useState([]);

    const defaultValues = {
        date: null,
        enrollmentWhoPay: null,
        billingMethod: null,
        paymentFrequency: null,
        card: card,
        ...data,
    };

    const validationSchema = Yup.object().shape({
        // paymentFrequency: Yup.object().required(t("This field is required.", { ns: "validation" })),
        // paymentFrequency: Yup.object().when("period", {
        //     is: (period) => period && period.value === "CYCLICAL",
        //     then: () =>
        //         Yup.object().required(t("This field is required.", { ns: "validation" })),
        //     otherwise: () => Yup.array(),
        // }),
    });

    useEffect(() => {
        setLoading(true);

        if (lexicons) {
            setEnrollmentWhoPayOptions(lexicons["enrollment"]["whoPay"]);
            setPaymentFrequencyOptions(lexicons["payment"]["frequency"]);
        }

        setAvailableDatesOptions(data.activity.date);

        let whoPay = [];
        data.activity.billingMethods.map((billingMethod) => {
            if (whoPay.some((e) => e.value === billingMethod.whoPay.value)) {
                return billingMethod;
            }
            return whoPay.push(billingMethod.whoPay);
        });

        setEnrollmentWhoPayOptions(whoPay);

        Promise.all([
            AxiosInstance({
                url: "activities/" + data.activity.id + "/documents",
                method: "GET",
            }).then((response) => {
                setActivityDocuments(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [data, lexicons]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/enroll",
            method: "POST",
            data: values,
        })
            .then((response) => {
                if (response.data.data.orderID && response.data.data.formContext) {
                    sessionStorage.setItem("payment_" + response.data.data.orderID, JSON.stringify(response.data.data));
                }

                setEnrolled(true);
                setEnrolledMessage(response.data.message);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);

                setTimeout(() => {
                    if (response.data.data.orderID && response.data.data.formContext) {
                        navigate("/payment/" + response.data.data.orderID);
                    }
                }, 1000);
            })
            .catch((error) => {
                if (error.response.data.message && error.response.data.message.indexOf("Invalid token")) {
                    setCard(null);
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    const handleSaveCard = (card) => {
        setCard(card);
    };

    return (
        <Modal handleClose={closeModal} title={t("Enroll in an activity", { ns: "common" })}>
            {enrolled ? (
                <>
                    <h3>{t(enrolledMessage, { ns: "server" })}</h3>
                </>
            ) : isLoading ? (
                <LoadingSpinner />
            ) : data.activity ? (
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values }) => {
                        return (
                            <Form>
                                <div className="flex flex-col gap-4 w-full">
                                    <div className="flex flex-col gap-2">
                                        {data.kid ? (
                                            <div className="flex items-center gap-2">
                                                <strong>{t("Name of the kid", { ns: "common" })}:</strong>
                                                {data.kid.fullName}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity", { ns: "common" })}:</strong>
                                            {data.activity.name}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Description", { ns: "common" })}:</strong>
                                            {data.activity.description}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Brand", { ns: "common" })}:</strong>
                                            {data.activity.brand.name}
                                        </div>
                                        {data.activity.period.value === "SINGLE" && (
                                            <>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Date", { ns: "common" })}:</strong>
                                                    <FormatDate date={data.activity.date[0].day} />
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Hour", { ns: "common" })}:</strong>
                                                    <FormatTime
                                                        date={data.activity.date[0].time}
                                                        formatting={{ hour: "numeric", minute: "numeric" }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Type of activity", { ns: "common" })}:</strong>
                                            {t(data.activity.period.name, { ns: "lexicons" })}
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <strong>{t("Activity length", { ns: "common" })}:</strong>
                                            <FormatMinutes minutes={data.activity.activityLength} />
                                        </div>
                                    </div>
                                    {(currentUser != null && currentUser.accountType === "parent") ?? (
                                        <>
                                            {data.activity.period.value === "CYCLICAL" && card === null ? (
                                                <>
                                                    <div className="border-t"></div>
                                                    <SecureForm handleSaveCard={handleSaveCard} />
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}
                                    {data.activity.period.value === "CYCLICAL" && (
                                        <>
                                            <div className="border-t"></div>

                                            <Field
                                                component={AutocompleteField}
                                                key={"date"}
                                                name={"date"}
                                                label={t("Available dates", { ns: "common" })}
                                                isMulti={true}
                                                required={true}
                                                fieldClassName={"col-span-2"}
                                                options={availableDatesOptions}
                                                getOptionLabel={(option) => {
                                                    return (
                                                        t(option.day.name, { ns: "lexicons" }) +
                                                        " - " +
                                                        dayjs(option.time).format("HH:mm")
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                    {values.date &&
                                        values.date.length > 0 &&
                                        values.date.map((d, index) => {
                                            let weekDay = d.day.id === 7 ? 0 : d.day.id;
                                            let time = dayjs(d.time);

                                            let minDate = dayjs()
                                                .set("hour", time.get("hour"))
                                                .set("minute", time.get("minute"))
                                                .set("second", 0)
                                                .day(weekDay);

                                            if (minDate < today) {
                                                minDate = minDate.add(7, "day");
                                            }

                                            let maxDate = minDate.add(5, "week");

                                            return (
                                                <Field
                                                    component={DatePicker}
                                                    key={"date" + index}
                                                    name={"date"}
                                                    label={
                                                        t("Date of first activity", { ns: "common" }) +
                                                        " - " +
                                                        t(d.day.name, { ns: "lexicons" }) +
                                                        " - " +
                                                        dayjs(d.time).format("HH:mm")
                                                    }
                                                    required={true}
                                                    defaultValue={minDate}
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    shouldDisableDate={(e) => {
                                                        return e.day() !== weekDay;
                                                    }}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            "date[" + index + "][firstActivity]",
                                                            dayjs(value).format()
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    {data.activity.isFree === false &&
                                        (data.activity.forWhom.value === "INSTITUTION" ? (
                                            <>
                                                <Field
                                                    component={AutocompleteField}
                                                    key={"enrollmentWhoPay"}
                                                    name={"enrollmentWhoPay"}
                                                    label={t("Who pays for the activity?", { ns: "common" })}
                                                    required={true}
                                                    fieldClassName={"col-span-2"}
                                                    options={enrollmentWhoPayOptions}
                                                    getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                                    onChange={(event, value) => {
                                                        setFieldValue("enrollmentWhoPay", value ? value : "");
                                                        setFieldValue("billingMethod", null);

                                                        let bM = [];
                                                        if (value) {
                                                            values.activity.billingMethods.map((billingMethod) => {
                                                                return (
                                                                    billingMethod.billingMethod.value !==
                                                                        "INSTITUTION_PAY_PER_KID_ACTIVITY" &&
                                                                    billingMethod.whoPay.value === value.value &&
                                                                    bM.push(billingMethod)
                                                                );
                                                            });
                                                        }

                                                        setBillingMethodOptions(bM);
                                                    }}
                                                />
                                                {values.enrollmentWhoPay && (
                                                    <>
                                                        <Field
                                                            component={AutocompleteField}
                                                            key={"billingMethod"}
                                                            name={"billingMethod"}
                                                            label={t("Billing method", { ns: "common" })}
                                                            required={true}
                                                            fieldClassName={"col-span-2"}
                                                            options={billingMethodOptions}
                                                            getOptionLabel={(option) => {
                                                                return (
                                                                    t(option.billingMethod.name, { ns: "lexicons" }) +
                                                                    " - " +
                                                                    t("{{price, currency(PLN)}}", {
                                                                        price: option.price,
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Field
                                                    component={AutocompleteField}
                                                    key={"billingMethod"}
                                                    name={"billingMethod"}
                                                    label={t("Billing method", { ns: "common" })}
                                                    required={true}
                                                    fieldClassName={"col-span-2"}
                                                    options={values.activity.billingMethods}
                                                    getOptionLabel={(option) => {
                                                        return (
                                                            t(option.billingMethod.name, { ns: "lexicons" }) +
                                                            " - " +
                                                            t("{{price, currency(PLN)}}", {
                                                                price: option.price,
                                                            })
                                                        );
                                                    }}
                                                />

                                                {data.activity.period.value === "CYCLICAL" && (
                                                    <>
                                                        <Field
                                                            component={AutocompleteField}
                                                            key={"paymentFrequency"}
                                                            name={"paymentFrequency"}
                                                            label={t("Frequency of payment", { ns: "common" })}
                                                            required={true}
                                                            fieldClassName={"col-span-2"}
                                                            options={paymentFrequencyOptions}
                                                            getOptionLabel={(option) =>
                                                                t(option.name, { ns: "lexicons" })
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ))}

                                    {activityDocuments.length > 0 &&
                                        ((currentUser.accountType === "institution" &&
                                            values.enrollmentWhoPay &&
                                            values.enrollmentWhoPay.value === "PAY_INSTITUTION") ||
                                            (currentUser.accountType === "parent" &&
                                                data.activity.forWhom.value === "PARENT")) && (
                                            <ActivityDocuments
                                                documents={activityDocuments}
                                                activityId={data.activity.id}
                                            />
                                        )}

                                    <div className="flex flex-row justify-center">
                                        {t("Do you want to enroll in a activity?", { ns: "common" })}
                                    </div>
                                    <div className="flex flex-row justify-center gap-2">
                                        <Button
                                            type={"submit"}
                                            className={"btn-primary"}
                                            onClick={handleSubmit}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            {isSubmitting ? t("Saving", { ns: "button" }) : t("Yes", { ns: "button" })}
                                        </Button>
                                        <Button className={"btn-gray"} onClick={closeModal} disabled={isSubmitting}>
                                            {t("No", { ns: "button" })}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                t("No activity found", { ns: "common" })
            )}
        </Modal>
    );
};
