import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import { SelectField, YesNoField } from "components/forms";
import { DisplayDayTime } from "utils/DisplayDate";

function ModalEnrollmentChangeKidRemove({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [enrollment, setEnrollment] = useState(null);

    const defaultValues = {
        isRemoveKid: true,
        whenRemoveKid: null,
    };

    const validationSchema = Yup.object().shape({
        isRemoveKid: Yup.boolean().required(t("This field is required.", { ns: "validation" })),
        whenRemoveKid: Yup.number().when("isRemoveKid", {
            is: true,
            then: () => Yup.number().required(t("This field is required.", { ns: "validation" })),
        }),
    });

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "enrollments/" + data.id,
                method: "GET",
            }).then((response) => {
                setEnrollment(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [data]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/" + data.id + "/change-kid-remove",
            method: "POST",
            data: values,
        })
            .then((response) => {
                handleLoadData();

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal
            handleClose={closeModal}
            title={t("Change the settings for removing a child from an activity", { ns: "common" })}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Formik
                        initialValues={getInitialValues(defaultValues, enrollment)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({
                            handleSubmit,
                            handleBlur,
                            isSubmitting,
                            isValid,
                            setFieldValue,
                            setFieldTouched,
                            values,
                            validateForm,
                        }) => {
                            return (
                                <Form>
                                    <div className="flex flex-col gap-4 w-full">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col gap-2">
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Activity", { ns: "common" })}:</strong>
                                                    {enrollment.activity.name}
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Institution", { ns: "common" })}:</strong>
                                                    {enrollment.institution && enrollment.institution.name}
                                                </div>
                                                {enrollment.activity.period.value === "SINGLE" ? (
                                                    <>
                                                        {enrollment.date.map((d) => {
                                                            return (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <strong>{t("Date", { ns: "common" })}:</strong>
                                                                        <FormatDate date={d.day} />
                                                                    </div>
                                                                    <div className="flex items-center gap-2">
                                                                        <strong>{t("Hour", { ns: "common" })}:</strong>
                                                                        <FormatTime
                                                                            date={d.time}
                                                                            formatting={{
                                                                                hour: "numeric",
                                                                                minute: "numeric",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="flex items-center gap-2">
                                                        <strong>{t("Date of activity", { ns: "common" })}:</strong>
                                                        {enrollment.date
                                                            .map((d) => {
                                                                return (
                                                                    <DisplayDayTime day={d.day.name} time={d.time} />
                                                                );
                                                            })
                                                            .reduce((prev, curr) => [prev, ", ", curr])}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="border-t"></div>

                                            <Field
                                                component={YesNoField}
                                                key={"isRemoveKid"}
                                                name={"isRemoveKid"}
                                                label={t("Remove the kid?", {
                                                    ns: "common",
                                                })}
                                                required={true}
                                                fieldClassName={"col-span-3 md:col-span-1"}
                                            />

                                            {values.isRemoveKid && (
                                                <Field
                                                    component={SelectField}
                                                    key={"whenRemoveKid"}
                                                    name={"whenRemoveKid"}
                                                    label={t("When remove the kid?", {
                                                        ns: "common",
                                                    })}
                                                    required={true}
                                                    fieldClassName={"col-span-full md:col-span-1"}
                                                    getOptionLabel={(option) =>
                                                        t("before {{activityNo}} activity after due date", {
                                                            ns: "lexicons",
                                                            activityNo: option,
                                                        })
                                                    }
                                                    options={[1, 2, 3, 4, 5, 6, 7, 8]}
                                                    onChange={(event) => {
                                                        setFieldValue("whenRemoveKid", event.target.value);
                                                    }}
                                                />
                                            )}

                                            <div className="flex justify-center gap-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={!isValid || isSubmitting}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isSubmitting
                                                        ? t("Saving", { ns: "button" })
                                                        : t("Save", { ns: "button" })}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-gray"
                                                    disabled={isSubmitting}
                                                    onClick={closeModal}
                                                >
                                                    {t("Cancel", { ns: "button" })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Modal>
    );
}

export default ModalEnrollmentChangeKidRemove;
