import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Box, Modal } from "components/ui";
import { IoAddOutline, IoCloudUploadOutline, IoDocumentTextOutline, IoTrashOutline } from "react-icons/io5";
import { ButtonBase, IconButton, Input, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import { FileDownload } from "utils/FileDownload";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

function ModalActivityDocuments({ data, closeModal }) {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);

    const [additionalDocuments, setAdditionalDocuments] = useState([]);
    const [newDocuments, setNewDocuments] = useState([]);

    useEffect(() => {
        handleLoadData();
    }, [data]);

    const handleLoadData = () => {
        setLoading(true);

        Promise.all([
            AxiosInstance({
                url: "activities/" + data.activity.id + "/documents",
                method: "GET",
            }).then((response) => {
                setAdditionalDocuments(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    const handleSubmit = async (index) => {
        let tempDocuments = [...newDocuments];
        tempDocuments[index].isSubmitting = true;
        setNewDocuments(tempDocuments);

        let document = newDocuments[index];

        const formData = new FormData();
        formData.append("file", document.file);
        formData.append("filename", document.filename);

        AxiosInstance({
            url: "activities/" + data.activity.id + "/documents",
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                enqueueSnackbar(t("File uploaded", { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setNewDocuments((newDocuments) => newDocuments.filter((_, i) => i !== index));
                    handleLoadData();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    let tempDocuments = [...newDocuments];
                    tempDocuments[index].isSubmitting = false;
                    setNewDocuments(tempDocuments);
                }, 300);
            });
    };

    const handleDelete = async (documentId) => {
        AxiosInstance({
            url: "activities/" + data.activity.id + "/documents/" + documentId,
            method: "DELETE",
        })
            .then((response) => {
                enqueueSnackbar(t("File removed", { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                    handleLoadData();
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Documents for activity", { ns: "common" })} size={"lg"}>
            <div className="flex items-center gap-2">
                <strong>{t("Activity", { ns: "common" })}:</strong>
                {data.activity.name}
            </div>

            <p className="mt-4">
                {t(
                    "Here you will find a list of documents that will be displayed when registering for activity. When you add a document, give it a name that will be displayed when you register for activity. Finally, click the “Upload” button to send the file to the server.",
                    { ns: "common" }
                )}
            </p>

            <div className="flex justify-between items-end mt-4">
                {(newDocuments.length > 0 || additionalDocuments.length > 0) && (
                    <strong>{t("Document name", { ns: "common" })}</strong>
                )}
                <ButtonBase
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    className={"btn btn-primary"}
                    sx={{
                        padding: "0.75rem 1.5rem",
                        borderRadius: "1.5rem",
                    }}
                >
                    <IoAddOutline size={"1.25rem"} />
                    {t("Add new document", { ns: "button" })}
                    <VisuallyHiddenInput
                        type="file"
                        accept=".pdf"
                        multiple={false}
                        onChange={(e) => {
                            if (e.target.files.length > 0) {
                                if (e.target.files[0].size > 5242880) {
                                    enqueueSnackbar(t("File is too large", { ns: "common" }), {
                                        variant: "error",
                                    });
                                    return;
                                }
                                setNewDocuments([
                                    ...newDocuments,
                                    { file: e.target.files[0], filename: e.target.files[0].name, isSubmitting: false },
                                ]);
                            }
                        }}
                    />
                </ButtonBase>
            </div>

            <div className="flex flex-col gap-2 !mt-4">
                {newDocuments.length > 0 &&
                    newDocuments.map((document, index) => (
                        <Box key={index} className="gap-4 flex items-center !p-4">
                            <Input
                                placeholder={t("Enter the document name", { ns: "common" })}
                                type="text"
                                value={document.filename}
                                className="flex-1"
                                onChange={(e) => {
                                    let tempDocuments = [...newDocuments];
                                    tempDocuments[index].filename = e.target.value;
                                    setNewDocuments(tempDocuments);
                                }}
                                disabled={document.isSubmitting}
                            />

                            <ButtonBase
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                className={"btn btn-primary"}
                                sx={{
                                    padding: "0.75rem 1.5rem",
                                    borderRadius: "1.5rem",
                                }}
                                onClick={() => handleSubmit(index)}
                                disabled={document.isSubmitting}
                            >
                                <IoCloudUploadOutline size={"1.25rem"} />
                                {t("Upload", { ns: "button" })}
                            </ButtonBase>
                        </Box>
                    ))}

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {additionalDocuments.length > 0 &&
                            additionalDocuments.map((document) => (
                                <Box key={document.id} className="gap-4 flex items-center !p-4">
                                    <IoDocumentTextOutline size={"2rem"} />
                                    <FileDownload
                                        slug={"activities/" + data.activity.id + "/documents/" + document.id}
                                        filename={document.filename}
                                        mimeType={document.mimeType}
                                        className={"flex-1 !justify-start !p-2"}
                                    >
                                        <span>{document.name}</span>
                                    </FileDownload>
                                    <Tooltip placement="top" title={t("Delete", { ns: "common" })}>
                                        <IconButton
                                            size="medium"
                                            onClick={() => handleDelete(document.id)}
                                            color="error"
                                            disabled={isSubmitting}
                                        >
                                            <IoTrashOutline size={"1.25rem"} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ))}
                    </>
                )}
            </div>
        </Modal>
    );
}

export default ModalActivityDocuments;
