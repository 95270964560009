import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatTime } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Modal } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import { NumberField, SelectField } from "components/forms";
import { DisplayDayTime } from "utils/DisplayDate";
import { useLexicons } from "hooks/useLexicons";

function ModalEnrollmentChangePaymentTerm({ data, closeModal, handleLoadData }) {
    const { t } = useTranslation();
    const lexicons = useLexicons();

    const [isLoading, setLoading] = useState(true);
    const [enrollment, setEnrollment] = useState(null);
    const [activityPaymentFormOptions, setActivityPaymentFormOptions] = useState([]);

    const defaultValues = {
        paymentForm: null,
        paymentTerm: null,
    };

    const validationSchema = Yup.object().shape({
        paymentTerm: Yup.number()
            .integer(t("The value must be an integer.", { ns: "validation" }))
            .required(t("This field is required.", { ns: "validation" }))
            .when("whoPay", {
                is: (whoPay) => whoPay && whoPay.value === "PAY_INSTITUTION",
                then: (schema) => {
                    return schema
                        .min(
                            0,
                            t("Value too low. The minimum value is {{min}}.", {
                                ns: "validation",
                                min: 0,
                            })
                        )
                        .max(
                            30,
                            t("Value too high. The maximum value is {{max}}.", {
                                ns: "validation",
                                max: 30,
                            })
                        );
                },
                otherwise: (schema) => {
                    return schema
                        .min(
                            1,
                            t("Value too low. The minimum value is {{min}}.", {
                                ns: "validation",
                                min: 1,
                            })
                        )
                        .max(
                            28,
                            t("Value too high. The maximum value is {{max}}.", {
                                ns: "validation",
                                max: 28,
                            })
                        );
                },
            }),
    });

    useEffect(() => {
        if (lexicons) {
            setActivityPaymentFormOptions(lexicons["activity"]["paymentForm"]);
        }

        Promise.all([
            AxiosInstance({
                url: "enrollments/" + data.id,
                method: "GET",
            }).then((response) => {
                setEnrollment(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [data]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "enrollments/" + data.id + "/change-payment-term",
            method: "POST",
            data: values,
        })
            .then((response) => {
                handleLoadData();

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Change the payment term of activity", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Formik
                        initialValues={getInitialValues(defaultValues, enrollment)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleSubmit(values, setSubmitting, resetForm);
                        }}
                        validateOnMount
                        enableReinitialize
                    >
                        {({
                            handleSubmit,
                            handleBlur,
                            isSubmitting,
                            isValid,
                            setFieldValue,
                            setFieldTouched,
                            values,
                            validateForm,
                        }) => {
                            return (
                                <Form>
                                    <div className="flex flex-col gap-4 w-full">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col gap-2">
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Activity", { ns: "common" })}:</strong>
                                                    {enrollment.activity.name}
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <strong>{t("Institution", { ns: "common" })}:</strong>
                                                    {enrollment.institution && enrollment.institution.name}
                                                </div>
                                                {enrollment.activity.period.value === "SINGLE" ? (
                                                    <>
                                                        {enrollment.date.map((d) => {
                                                            return (
                                                                <>
                                                                    <div className="flex items-center gap-2">
                                                                        <strong>{t("Date", { ns: "common" })}:</strong>
                                                                        <FormatDate date={d.day} />
                                                                    </div>
                                                                    <div className="flex items-center gap-2">
                                                                        <strong>{t("Hour", { ns: "common" })}:</strong>
                                                                        <FormatTime
                                                                            date={d.time}
                                                                            formatting={{
                                                                                hour: "numeric",
                                                                                minute: "numeric",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="flex items-center gap-2">
                                                        <strong>{t("Date of activity", { ns: "common" })}:</strong>
                                                        {enrollment.date
                                                            .map((d) => {
                                                                return (
                                                                    <DisplayDayTime day={d.day.name} time={d.time} />
                                                                );
                                                            })
                                                            .reduce((prev, curr) => [prev, ", ", curr])}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="border-t"></div>

                                            {values.whoPay.value === "PAY_INSTITUTION" && (
                                                <Field
                                                    component={SelectField}
                                                    key={`paymentForm`}
                                                    name={`paymentForm`}
                                                    required={true}
                                                    label={t("Payment form", {
                                                        ns: "common",
                                                    })}
                                                    fieldClassName={"col-span-full md:col-span-1"}
                                                    options={activityPaymentFormOptions}
                                                    getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                                    disabled={true}
                                                />
                                            )}

                                            <Field
                                                component={NumberField}
                                                key={`paymentTerm`}
                                                name={`paymentTerm`}
                                                handleBlur={handleBlur}
                                                required={true}
                                                label={t("Payment term", { ns: "common" })}
                                                fieldClassName={"col-span-full md:col-span-1"}
                                                tooltipTitle={
                                                    <ul className="text-sm italic list-disc ml-4">
                                                        {values.whoPay.value === "PAY_INSTITUTION" ? (
                                                            <>
                                                                <li>
                                                                    {t("You can set values from 0 to 30.", {
                                                                        ns: "common",
                                                                    })}
                                                                </li>
                                                                <li>
                                                                    {t(
                                                                        "0 - invoice due date is equal to the date of invoice generation",
                                                                        { ns: "common" }
                                                                    )}
                                                                </li>
                                                                <li>
                                                                    {t(
                                                                        "1-30 - invoice payment term equals the day of invoice generation plus 1-30 days",
                                                                        { ns: "common" }
                                                                    )}
                                                                </li>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <li>
                                                                    {t("You can set values from 1 to 28.", {
                                                                        ns: "common",
                                                                    })}
                                                                </li>
                                                                <li>
                                                                    {t(
                                                                        "The value indicates the day of the month by which the payment for classes must be made.",
                                                                        { ns: "common" }
                                                                    )}
                                                                </li>
                                                            </>
                                                        )}
                                                    </ul>
                                                }
                                            />

                                            <div className="flex justify-center gap-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={!isValid || isSubmitting}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {isSubmitting
                                                        ? t("Saving", { ns: "button" })
                                                        : t("Save", { ns: "button" })}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-gray"
                                                    disabled={isSubmitting}
                                                    onClick={closeModal}
                                                >
                                                    {t("Cancel", { ns: "button" })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </Modal>
    );
}

export default ModalEnrollmentChangePaymentTerm;
