import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { IoChevronBackOutline, IoChevronForwardOutline, IoMenuOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { Box } from "../ui/Box";
import { AddEventSidebar, Calendar, EventPopUp, SidebarLeft } from "components/calendar";
import { LoadingSpinner } from "components/common";
import { Button } from "components/ui";

export const AppCalendar = () => {
    const calendarRef = useRef(null);
    const { t } = useTranslation();
    const theme = useTheme();

    const [currentTitle, setCurrentTitle] = useState(null);
    const [currentView, setCurrentView] = useState("dayGridMonth");
    const [isLoading, setLoading] = useState(true);
    const [isReLoading, setReLoading] = useState(false);
    const [store, setStore] = useState(null);
    const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
    const [eventPopUpOpen, setEventPopUpOpen] = useState(false);
    const [addEventSidebarOpen, setAddEventSidebarOpen] = useState(false);

    const leftSidebarWidth = 300;
    const addEventSidebarWidth = 400;
    const mdAbove = useMediaQuery(theme.breakpoints.up("xl"));

    const generalCalendarName = t("General calendar", { ns: "common" });

    const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
    const handleEventPopUpToggle = () => setEventPopUpOpen(!eventPopUpOpen);
    const handleAddEventSidebarToggle = () => setAddEventSidebarOpen(!addEventSidebarOpen);

    async function reloadCalendars(selectedCalendars, period) {
        setReLoading(true);

        AxiosInstance({
            url: "calendars/by-selected-calendars",
            method: "POST",
            data: {
                selectedCalendars: selectedCalendars,
                period: period,
            },
        }).then((response) => {
            setStore(response.data);

            setTimeout(() => {
                setReLoading(false);
            }, 300);
        });
    }

    useEffect(() => {
        setCurrentTitle(dayjs().format("MMMM YYYY"));

        AxiosInstance({
            url: "calendars",
            method: "GET",
        }).then((response) => {
            setStore(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [generalCalendarName]);

    const handleChangePeriod = (step) => {
        const calendarApi = calendarRef.current.getApi();
        const currentSelectedCalendars = store.selectedCalendars;

        if (step === "next") {
            calendarApi.next();
        } else {
            calendarApi.prev();
        }

        const period = {
            start: dayjs(calendarApi.view.activeStart).format(),
            end: dayjs(calendarApi.view.activeEnd).format(),
        };

        setCurrentTitle(calendarApi.view.title);
        reloadCalendars(currentSelectedCalendars, period);
    };

    const handleChangeView = (view) => {
        const calendarApi = calendarRef.current.getApi();
        const currentSelectedCalendars = store.selectedCalendars;

        calendarApi.changeView(view);

        const period = {
            start: dayjs(calendarApi.view.activeStart).format(),
            end: dayjs(calendarApi.view.activeEnd).format(),
        };

        setCurrentView(view);
        setCurrentTitle(calendarApi.view.title);
        reloadCalendars(currentSelectedCalendars, period);
    };

    const handleCalendarsUpdate = (key) => {
        const currentPeriod = store.period;
        const currentSelectedCalendars = store.selectedCalendars;
        const filterIndex = currentSelectedCalendars.findIndex((i) => i === key);

        if (currentSelectedCalendars.includes(key)) {
            currentSelectedCalendars.splice(filterIndex, 1);
        } else {
            currentSelectedCalendars.push(key);
        }

        if (currentSelectedCalendars.length === 0) {
            currentSelectedCalendars.length = 0;
        }

        reloadCalendars(currentSelectedCalendars, currentPeriod);
    };

    return (
        <Box className={"app-calendar relative "}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <SidebarLeft
                        store={store}
                        mdAbove={mdAbove}
                        isReLoading={isReLoading}
                        leftSidebarOpen={leftSidebarOpen}
                        leftSidebarWidth={leftSidebarWidth}
                        handleCalendarsUpdate={handleCalendarsUpdate}
                        handleLeftSidebarToggle={handleLeftSidebarToggle}
                    />

                    <div className="w-full">
                        <div className="toolbar flex flex-col lg:flex-row items-center justify-between gap-4 p-6">
                            <div className="flex items-center justify-between w-full lg:w-auto">
                                {!mdAbove && (
                                    <>
                                        <Button onClick={() => handleLeftSidebarToggle()} className="!p-0">
                                            <IoMenuOutline size={"1.75rem"} />
                                        </Button>
                                        <div className="flex-grow"></div>
                                        <h3 className="text-lg">{currentTitle}</h3>
                                        <div className="flex-grow"></div>
                                    </>
                                )}
                                <Button
                                    className="btn-sm !px-2"
                                    onClick={() => handleChangePeriod("prev")}
                                    disabled={isReLoading}
                                >
                                    <IoChevronBackOutline size={"1.25rem"} />
                                </Button>
                                <Button
                                    className="btn-sm !px-2"
                                    onClick={() => handleChangePeriod("next")}
                                    disabled={isReLoading}
                                >
                                    <IoChevronForwardOutline size={"1.25rem"} />
                                </Button>
                                {mdAbove && <h3 className="text-lg">{currentTitle}</h3>}
                            </div>
                            <div className="flex">
                                <Button
                                    className={
                                        "btn-sm !rounded-e-none  " +
                                        (currentView === "dayGridMonth" ? "btn-primary" : "btn-gray")
                                    }
                                    onClick={() => handleChangeView("dayGridMonth")}
                                    disabled={isReLoading}
                                >
                                    {t("month", { ns: "button" })}
                                </Button>
                                <Button
                                    className={
                                        "btn-sm !rounded-none " +
                                        (currentView === "timeGridWeek" ? "btn-primary" : "btn-gray")
                                    }
                                    onClick={() => handleChangeView("timeGridWeek")}
                                    disabled={isReLoading}
                                >
                                    {t("week", { ns: "button" })}
                                </Button>
                                <Button
                                    className={
                                        "btn-sm !rounded-none " +
                                        (currentView === "timeGridDay" ? "btn-primary" : "btn-gray")
                                    }
                                    onClick={() => handleChangeView("timeGridDay")}
                                    disabled={isReLoading}
                                >
                                    {t("day", { ns: "button" })}
                                </Button>
                                <Button
                                    className={
                                        "btn-sm !rounded-s-none " +
                                        (currentView === "listMonth" ? "btn-primary" : "btn-gray")
                                    }
                                    onClick={() => handleChangeView("listMonth")}
                                    disabled={isReLoading}
                                >
                                    {t("list", { ns: "button" })}
                                </Button>
                            </div>
                        </div>
                        <div className="calendar-content relative">
                            {isReLoading && (
                                <div className="bg-white absolute top-0 left-0 right-0 bottom-0 z-10">
                                    <LoadingSpinner />
                                </div>
                            )}
                            <Calendar
                                calendarRef={calendarRef}
                                store={store}
                                mdAbove={mdAbove}
                                leftSidebarOpen={leftSidebarOpen}
                                leftSidebarWidth={leftSidebarWidth}
                                handleCalendarsUpdate={handleCalendarsUpdate}
                                handleLeftSidebarToggle={handleLeftSidebarToggle}
                                handleEventPopUpToggle={handleEventPopUpToggle}
                                handleAddEventSidebarToggle={handleAddEventSidebarToggle}
                            />
                        </div>
                    </div>

                    {store.selectedEvent !== null && store.selectedEvent.id.length ? (
                        <EventPopUp
                            eventId={store.selectedEvent.id}
                            calendar={store.selectedEvent.extendedProps.calendar}
                            eventPopUpOpen={eventPopUpOpen}
                            handleEventPopUpToggle={handleEventPopUpToggle}
                        />
                    ) : null}

                    <AddEventSidebar
                        store={store}
                        addEventSidebarOpen={addEventSidebarOpen}
                        addEventSidebarWidth={addEventSidebarWidth}
                        handleAddEventSidebarToggle={handleAddEventSidebarToggle}
                    />
                </>
            )}
        </Box>
    );
};
