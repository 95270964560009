import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { createFilterOptions } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { FormatPrice } from "utils/Format";
import { NumberField, AutocompleteField } from "components/forms";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";

import ModalBillingAddProduct from "./ModalBillingAddProduct";

function ModalBillingAdditionalFee({ data, closeModal }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [isLoading, setLoading] = useState(true);

    const [groups, setGroups] = useState([]);
    const [kids, setKids] = useState([]);
    const [products, setProducts] = useState([]);

    const forWhomOptions = [
        {
            value: "ALL",
            name: "All children",
        },
        {
            value: "GROUP",
            name: "Selected groups",
        },
        {
            value: "KID",
            name: "Selected kids",
        },
    ];

    const defaultValues = {
        billingPeriod: { month: data.period.month() + 1, year: data.period.year() },
        forWhom: "",
        value: [],
        products: [],
    };

    const validationSchema = Yup.object().shape({
        billingPeriod: Yup.object().shape({
            month: Yup.number()
                .integer()
                .min(
                    1,
                    t("The {{label}} field must be greater than or equal to 1.", {
                        ns: "validation",
                        label: t("Month", { ns: "common" }),
                    })
                )
                .max(
                    12,
                    t("The {{label}} field must be less than or equal to 12.", {
                        ns: "validation",
                        label: t("Month", { ns: "common" }),
                    })
                )
                .required(t("This field is required.", { ns: "validation" })),
            year: Yup.number()
                .integer()
                .min(
                    2024,
                    t("The {{label}} field must be greater than or equal to 2024.", {
                        ns: "validation",
                        label: t("Year", { ns: "common" }),
                    })
                )
                .max(
                    2099,
                    t("The {{label}} field must be less than or equal to 2099.", {
                        ns: "validation",
                        label: t("Year", { ns: "common" }),
                    })
                )
                .required(t("This field is required.", { ns: "validation" })),
        }),
        forWhom: Yup.object().required(t("This field is required.", { ns: "validation" })),
        value: Yup.array().when("forWhom", {
            is: (forWhom) => forWhom && (forWhom.value === "GROUP" || forWhom.value === "KID"),
            then: () => Yup.array().min(1, t("This field is required.", { ns: "validation" })),
            otherwise: () => Yup.array(),
        }),
        products: Yup.array().min(1, t("This field is required.", { ns: "validation" })),
    });

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
        handleLoadData();
    };

    useEffect(() => {
        handleLoadData();
    }, [currentUser.institution.id, data]);

    const handleLoadData = () => {
        Promise.all([
            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/groups",
                method: "GET",
            }).then((response) => {
                setGroups(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),

            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/kids/active",
                method: "GET",
            }).then((response) => {
                setKids(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),

            AxiosInstance({
                url:
                    "institutions/" +
                    currentUser.institution.id +
                    "/products/" +
                    data.period.year() +
                    "/" +
                    (data.period.month() + 1) +
                    "/additional-fees",
                method: "GET",
            }).then((response) => {
                setProducts(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    };

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/billings/additional-fees",
            method: "POST",
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                resetForm(defaultValues);
                handleModalClose();

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal handleClose={closeModal} title={t("Additional fees", { ns: "common" })} size={"lg"}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <Formik
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        validateForm,
                    }) => {
                        return (
                            <Form key={"additionalFeesForm"}>
                                <div className="grid grid-cols-6 gap-6">
                                    <Field
                                        component={NumberField}
                                        key={"billingPeriod.month"}
                                        name={"billingPeriod.month"}
                                        value={values.billingPeriod.month}
                                        label={t("Period - month", { ns: "common" })}
                                        required={true}
                                        fieldClassName={"col-span-3"}
                                    />

                                    <Field
                                        component={NumberField}
                                        key={"billingPeriod.year"}
                                        name={"billingPeriod.year"}
                                        label={t("Period - year", { ns: "common" })}
                                        required={true}
                                        fieldClassName={"col-span-3"}
                                    />

                                    <Field
                                        component={AutocompleteField}
                                        key={"forWhom"}
                                        name={"forWhom"}
                                        label={t("For whom", { ns: "common" })}
                                        required={true}
                                        fieldClassName={"col-span-full"}
                                        handleBlur={handleBlur}
                                        options={forWhomOptions}
                                        getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                        onChange={(event, value) => {
                                            setFieldValue("forWhom", value);
                                            setFieldValue("value", []);
                                        }}
                                    />

                                    {values.forWhom &&
                                        (values.forWhom.value === "GROUP" || values.forWhom.value === "KID") && (
                                            <Field
                                                isMulti
                                                component={AutocompleteField}
                                                key={"value"}
                                                name={"value"}
                                                label={
                                                    values.forWhom.value === "GROUP"
                                                        ? t("Select groups", { ns: "common" })
                                                        : t("Select kids", { ns: "common" })
                                                }
                                                required={true}
                                                fieldClassName={"col-span-full"}
                                                handleBlur={handleBlur}
                                                options={values.forWhom.value === "GROUP" ? groups : kids}
                                                getOptionLabel={(option) =>
                                                    values.forWhom.value === "GROUP" ? option.name : option.fullName
                                                }
                                                filterOptions={createFilterOptions({
                                                    matchFrom: "any",
                                                    stringify: (option) => option.fullName,
                                                })}
                                            />
                                        )}

                                    <Field
                                        isMulti
                                        component={AutocompleteField}
                                        key={"products"}
                                        name={"products"}
                                        label={t("Select products", { ns: "common" })}
                                        required={true}
                                        fieldClassName={"col-span-full"}
                                        handleBlur={handleBlur}
                                        options={products}
                                    />

                                    {values.products && values.products.length > 0 && (
                                        <div className="col-span-full">
                                            <div className="grid grid-cols-6 gap-2">
                                                {values.products.map((product, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <div className="col-span-3">
                                                                {index + 1}. {product.name}
                                                            </div>
                                                            <div className="col-span-2 flex items-center justify-end gap-1">
                                                                <div className="form-field w-24">
                                                                    <input
                                                                        type="number"
                                                                        className={"form-input !py-1 !px-2"}
                                                                        min={0}
                                                                        value={product.price}
                                                                        onChange={(event) => {
                                                                            values.products[index].price =
                                                                                event.target.value;
                                                                            setFieldValue("products", values.products);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <span>*</span>
                                                                <div className="form-field w-16">
                                                                    <input
                                                                        type="number"
                                                                        className={"form-input !py-1 !px-2"}
                                                                        min={0}
                                                                        max={31}
                                                                        step={1}
                                                                        value={product.quantity}
                                                                        onChange={(event) => {
                                                                            values.products[index].quantity =
                                                                                event.target.value;
                                                                            setFieldValue("products", values.products);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 text-end">
                                                                <FormatPrice price={product.price * product.quantity} />
                                                            </div>
                                                        </Fragment>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}

                                    <Button
                                        type={"submit"}
                                        className={"btn-primary !col-start-1 col-span-3"}
                                        disabled={!isValid || isSubmitting}
                                    >
                                        {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                    </Button>
                                    <Button
                                        className={"btn-gray col-span-3"}
                                        onClick={() => openModal({}, "addProduct")}
                                    >
                                        {t("Add new product", { ns: "button" })}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}

            {modal.show && modal.data && modal.type === "addProduct" && (
                <ModalBillingAddProduct data={modal.data} openModal={openModal} closeModal={handleModalClose} />
            )}
        </Modal>
    );
}

export default ModalBillingAdditionalFee;
