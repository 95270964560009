import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatDate, FormatTime } from "utils/Format";
import { enqueueSnackbar } from "notistack";
import { Checkbox } from "@mui/material";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { Modal } from "components/ui";
import { DataGrid } from "components/grid";
import { LoadingSpinner } from "components/common";
import { GroupIcon } from "pages/Institution/components/GroupIcon";

export const EventPopUp = ({ eventId, calendar, eventPopUpOpen, handleEventPopUpToggle }) => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const isRights =
        currentUser.roles.includes("ROLE_ADMIN") ||
        currentUser.roles.includes("ROLE_PROVIDER_ADMIN") ||
        currentUser.roles.includes("ROLE_PROVIDER_ASSISTANT") ||
        currentUser.roles.includes("ROLE_PROVIDER_TRAINER");

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [event, setEvent] = useState(null);

    const columns = [
        { field: "fullName", headerName: t("Full name", { ns: "common" }), flex: 1, minWidth: 180 },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            flex: 1,
            minWidth: 120,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
            valueFormatter: (value) => `${value}`,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-4">
                        <GroupIcon icon={params.row.group.icon} color={params.row.group.color} />
                        {params.row.group.name || ""}
                    </div>
                );
            },
        },
        {
            field: "attendance",
            headerName: t("Attendance", { ns: "common" }),
            width: 120,
            renderCell: (params) => {
                return (
                    <Checkbox
                        checked={params.row.attendance}
                        onChange={() => handleKidPresence(params.row)}
                        disabled={isSubmitting || !isRights}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        setLoading(true);

        AxiosInstance({
            url: "events/" + eventId,
            method: "GET",
        })
            .then((response) => {
                setEvent(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [eventId]);

    const handleKidPresence = async (kid) => {
        setSubmitting(true);

        AxiosInstance({
            url: "events/" + eventId + "/attendance",
            method: "PUT",
            data: {
                kid: kid.id,
            },
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setEvent(response.data.data);

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal
            size={"lg"}
            open={eventPopUpOpen}
            handleClose={handleEventPopUpToggle}
            title={t("Information about activity", { ns: "common" })}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : event ? (
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Name", { ns: "common" })}:</strong>
                            {event.title}
                        </div>
                        {event.activityDescription && (
                            <div className="flex items-center gap-2">
                                <strong>{t("Description of activities", { ns: "common" })}:</strong>
                                {event.activityDescription}
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <strong>{t("Instructor", { ns: "common" })}:</strong>
                            {event.data.trainers
                                ? event.data.trainers
                                      .map((trainer) => {
                                          return trainer.fullName;
                                      })
                                      .join(", ")
                                : t("lack", { ns: "common" })}
                        </div>
                        {event.data.enrollment.type.value === "IN_INSTITUTION" && (
                            <>
                                <div className="flex items-center gap-2">
                                    <strong>{t("Institution", { ns: "common" })}:</strong>
                                    {event.data.institution.name}
                                </div>
                                <div className="flex items-center gap-2">
                                    <strong>{t("Group", { ns: "common" })}:</strong>
                                    {event.data.groups
                                        ? event.data.groups
                                              .map((group) => {
                                                  return group.name;
                                              })
                                              .join(", ")
                                        : t("lack", { ns: "common" })}
                                </div>
                            </>
                        )}
                    </div>

                    <div className="border-t"></div>

                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Start", { ns: "common" })}:</strong>
                            <FormatDate date={event.start} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Hour", { ns: "common" })}:</strong>
                            <FormatTime date={event.start} formatting={{ hour: "numeric", minute: "numeric" }} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("End", { ns: "common" })}:</strong>
                            <FormatDate date={event.end} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Hour", { ns: "common" })}:</strong>
                            <FormatTime date={event.end} formatting={{ hour: "numeric", minute: "numeric" }} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Description", { ns: "common" })}:</strong>
                            {event.description ? event.description : t("lack", { ns: "common" })}
                        </div>
                        {currentUser.roles.includes("ROLE_PARENT") && (
                            <div className="flex items-center gap-2">
                                <strong>{t("Attendance", { ns: "common" })}:</strong>
                                {event.data.kids
                                    ? event.data.kids.find((kid) => kid.fullName === calendar).attendance === true
                                        ? t("yes", { ns: "common" })
                                        : t("no", { ns: "common" })
                                    : t("lack", { ns: "common" })}
                            </div>
                        )}
                    </div>

                    {!currentUser.roles.includes("ROLE_PARENT") && (
                        <>
                            <div className="border-t"></div>

                            <div className="flex items-center gap-2">
                                <strong>{t("List of kids", { ns: "common" })}:</strong>
                            </div>

                            <DataGrid
                                rows={event.data.kids}
                                columns={columns}
                                enableSearch={true}
                                staticHeight={true}
                                fileName={"ListaDzieci"}
                                disableColumnResize={false}
                            />
                        </>
                    )}
                </div>
            ) : null}
        </Modal>
    );
};
