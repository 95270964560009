import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IoAccessibilityOutline,
    IoEyeOutline,
    IoPeopleOutline,
    IoSearchOutline,
    IoWalletOutline,
} from "react-icons/io5";

import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { Badge, Button, ContentHeading, OptionIconButton, OptionMenu } from "components/ui";
import { DataEmpty } from "components/common";
import { SearchForm } from "components/activitySearch";
import { DataGrid } from "components/grid";
import ActivityModalView from "./ActivityModalView";
import ActivityModalGroup from "./ActivityModalGroup";
import ModalActivityPayments from "./ModalActivityPayments";
import { EnrollmentStatus } from "utils/EnrollmentStatus";

function ActivitiesList({ activities, handleLoadData }) {
    const { t } = useTranslation();

    const [activeSearch, setActiveSearch] = useState(false);
    const [modal, setModal] = useState({ show: false, data: null, type: null });

    const columns = [
        {
            field: "activity",
            headerName: t("Activity", { ns: "common" }),
            width: 320,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            valueFormatter: (value) => `${t(value, { ns: "lexicons" })}`,
            valueGetter: (value, row) => {
                value = EnrollmentStatus(value);
                return `${value.name}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.status.color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "brand",
            headerName: t("Brand", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "groups",
            headerName: t("Groups", { ns: "common" }),
            width: 220,
            valueGetter: (value, row) => `${(value && value.map((group) => group.name).join(", ")) || "---"}`,
        },
        {
            field: "when",
            headerName: t("When", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return params.row.activity.period.value === "SINGLE" ? (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDateTime date={d.day} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                ) : (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDayTime day={d.day.name} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                );
            },
        },
        {
            field: "whoPay",
            headerName: t("Who pay", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            valueGetter: (value, row) =>
                `${
                    (value && t(value.name, { ns: "lexicons" })) || (row.isFree && t("Free", { ns: "common" })) || "---"
                }`,
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                let iconButtons = [
                    {
                        icon: <IoEyeOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("View details", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal(params.row),
                        },
                    },
                ];

                let options = [
                    {
                        text: t("Groups", { ns: "button" }),
                        icon: <IoPeopleOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "group"),
                        },
                    },
                ];

                params.row.billing.id &&
                    options.push({
                        text: t("Payments", { ns: "button" }),
                        icon: <IoWalletOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row.billing, "payments"),
                        },
                    });

                return <OptionMenu iconButtons={iconButtons} options={options} />;
            },
        },
    ];

    useEffect(() => {
        handleLoadData();
    }, [activeSearch]);

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnComponent={
                    <Button className={"btn-primary"} onClick={() => setActiveSearch(true)}>
                        <IoSearchOutline size={"1.125rem"} />
                        {t("Find new activities", { ns: "common" })}
                    </Button>
                }
            >
                <IoAccessibilityOutline size={"1.5rem"} />
                {t("Institution activities", { ns: "common" })}
            </ContentHeading>

            {activeSearch ? (
                <SearchForm setActiveSearch={setActiveSearch} />
            ) : activities ? (
                <DataGrid rows={activities} columns={columns} openModal={openModal} />
            ) : (
                <DataEmpty title={t("Institution is not enrolled in any activities.", { ns: "common" })} />
            )}

            {modal.show && modal.data && modal.type === "view" && (
                <ActivityModalView data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "group" && (
                <ActivityModalGroup data={modal.data} closeModal={handleModalClose} handleLoadData={handleLoadData} />
            )}

            {modal.show && modal.data && modal.type === "payments" && (
                <ModalActivityPayments billing={modal.data} closeModal={handleModalClose} />
            )}
        </>
    );
}

export default ActivitiesList;
