import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoChevronBackOutline, IoChevronForwardOutline, IoRefreshOutline, IoWalletOutline } from "react-icons/io5";
import dayjs from "dayjs";

import AxiosInstance from "utils/AxiosInstance";
import CurrentUser from "services/CurrentUser";
import { Badge, Button, ContentHeading, OptionMenu } from "components/ui";
import { Dashboard, LoadingSpinner } from "components/common";
import { DataGrid } from "components/grid";
import { FormatPrice } from "utils/Format";
import ModalBillingCurrentMonth from "./components/ModalBillingCurrentMonth";
import { PaymentStatus } from "utils/PaymentStatus";
import { Tooltip } from "@mui/material";
import ModalBillingAdditionalFee from "./components/ModalBillingAdditionalFee";
import ModalRefundsPayment from "components/modal/ModalRefundsPayment";

export const BillingsPage = () => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [isLoading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [billingPeriod, setBillingPeriod] = useState(null);

    const columns = [
        {
            field: "firstName",
            headerName: t("First name", { ns: "common" }),
            width: 180,
            renderCell: (params) => (
                <div className="flex justify-between items-center py-2 min-h-12 h-full">{params.row.firstName}</div>
            ),
        },
        {
            field: "lastName",
            headerName: t("Last name", { ns: "common" }),
            width: 180,
            renderCell: (params) => (
                <div className="flex justify-between items-center py-2 min-h-12 h-full">{params.row.lastName}</div>
            ),
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 140,
            valueGetter: (value, row) => {
                if (row.payment) {
                    let status = PaymentStatus(row.payment.status.value, row.payment.dueDate, row.payment.required);
                    row.color = status.color;

                    return `${status.title}`;
                }
            },
            valueFormatter: (value) => `${t(value, { ns: "lexicons" })}`,
            renderCell: (params) => {
                if (params.row.payment) {
                    return (
                        <div className="flex items-center h-full">
                            <Badge color={params.row.color}>{t(params.value, { ns: "lexicons" })}</Badge>
                        </div>
                    );
                } else {
                    return <div className="flex justify-between items-center py-1 min-h-12 h-full">---</div>;
                }
            },
        },
        {
            field: "payment.fixed",
            headerName: t("Fixed payments", { ns: "common" }),
            flex: 1,
            minWidth: 360,
            valueGetter: (value, row) => {
                return row.payment.items
                    .filter((p) => p.type.value === "FIXED")
                    .map((item, p) => {
                        return item.name;
                    })
                    .join(", ");
            },
            renderCell: (params) => {
                let isEmpty = true;

                return (
                    <div className="flex justify-between items-center py-1 min-h-12">
                        {params.row.payment && params.row.payment.items.length > 0 ? (
                            <ul className="flex flex-col gap-1 w-full">
                                {params.row.payment.items.map((p, index) => {
                                    if (p.type.value === "FIXED") {
                                        isEmpty = false;
                                        return (
                                            <li key={index} className="flex justify-between items-center w-full gap-1">
                                                <div className="flex justify-between items-center w-full gap-1">
                                                    <span>{p.name}</span>

                                                    {p.discount ? (
                                                        <Tooltip
                                                            placement="top"
                                                            title={
                                                                p.discount.name +
                                                                " - " +
                                                                (p.discount.type.value === "PERCENTAGE"
                                                                    ? p.discount.value + "%"
                                                                    : t("{{price, currency(PLN)}}", {
                                                                          price: p.discount.value,
                                                                      }))
                                                            }
                                                        >
                                                            <div className="flex flex-col">
                                                                <s className="text-xs">
                                                                    <FormatPrice price={p.price} />
                                                                </s>
                                                                {p.discount.type.value === "PERCENTAGE" ? (
                                                                    <FormatPrice
                                                                        price={
                                                                            p.price - p.price * (p.discount.value / 100)
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <FormatPrice price={p.price - p.discount.value} />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <FormatPrice price={p.price} />
                                                    )}
                                                </div>
                                            </li>
                                        );
                                    }

                                    return null;
                                })}

                                {isEmpty && "---"}
                            </ul>
                        ) : (
                            "---"
                        )}
                    </div>
                );
            },
        },
        {
            field: "payment.presence",
            headerName: t("Presence payments", { ns: "common" }),
            flex: 1,
            minWidth: 360,
            valueGetter: (value, row) => {
                return row.payment.items
                    .filter((p) => p.type.value === "PRESENCE")
                    .map((item, p) => {
                        return item.name;
                    })
                    .join(", ");
            },
            renderCell: (params) => {
                let isEmpty = true;

                return (
                    <div className="flex justify-between items-center py-1 min-h-12">
                        {params.row.payment && params.row.payment.items.length > 0 ? (
                            <ul className="flex flex-col gap-1 w-full">
                                {params.row.payment.items.map((p, index) => {
                                    if (p.type.value === "PRESENCE") {
                                        isEmpty = false;
                                        return (
                                            <li key={index} className="flex justify-between items-center w-full gap-1">
                                                <div className="flex justify-between items-center w-full gap-1">
                                                    <span>{p.name}</span>
                                                    {p.discount ? (
                                                        <Tooltip
                                                            placement="top"
                                                            title={
                                                                p.discount.name +
                                                                " - " +
                                                                (p.discount.type.value === "PERCENTAGE"
                                                                    ? p.discount.value + "%"
                                                                    : t("{{price, currency(PLN)}}", {
                                                                          price: p.discount.value,
                                                                      }))
                                                            }
                                                        >
                                                            <div className="flex flex-col">
                                                                <s className="text-xs">
                                                                    <FormatPrice price={p.price * p.quantity} />
                                                                </s>
                                                                {p.discount.type.value === "PERCENTAGE" ? (
                                                                    <FormatPrice
                                                                        price={
                                                                            (p.price -
                                                                                p.price * (p.discount.value / 100)) *
                                                                            p.quantity
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <FormatPrice price={p.price - p.discount.value} />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <FormatPrice price={p.price * p.quantity} />
                                                    )}
                                                </div>
                                            </li>
                                        );
                                    }

                                    return null;
                                })}

                                {isEmpty && "---"}
                            </ul>
                        ) : (
                            "---"
                        )}
                    </div>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Total Amount", { ns: "common" }),
            width: 140,
            valueGetter: (value, row) => {
                return row.payment.discountAmount
                    ? row.payment.amount - row.payment.discountAmount
                    : row.payment.amount;
            },
            renderCell: (params) => {
                return (
                    <div className="flex justify-between items-center py-2 min-h-12 h-full">
                        {params.row.payment.discountAmount ? (
                            <div className="flex flex-col justify-center h-full leading-4">
                                <s className="text-xs">
                                    <FormatPrice price={params.row.payment.amount} />
                                </s>
                                <span>
                                    <FormatPrice
                                        price={params.row.payment.amount - params.row.payment.discountAmount}
                                    />
                                </span>
                            </div>
                        ) : (
                            <FormatPrice price={params.row.payment.amount} />
                        )}
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 100,
            renderCell: (params) => {
                let options = [];

                if (
                    params.row.payment.status.value === "COMPLETED" ||
                    params.row.payment.status.value === "REFUNDED" ||
                    params.row.payment.status.value === "PARTIAL_REFUNDED"
                ) {
                    options.push({
                        text: t("Refunds", { ns: "button" }),
                        icon: <IoRefreshOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row.payment.orderId, "refundsPayment"),
                        },
                    });
                }

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {
        setBillingPeriod(dayjs().set("date", 1));

        handleLoadBillings();
    }, []);

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
        handleLoadBillings();
    };

    const handleChangePeriod = (step) => {
        setLoading(true);
        setPayments([]);

        let newBillingPeriod = billingPeriod;

        if (step === "prev") {
            newBillingPeriod = newBillingPeriod.subtract(1, "month");
        } else {
            newBillingPeriod = newBillingPeriod.add(1, "month");
        }

        setBillingPeriod(newBillingPeriod);

        handleLoadBillings(newBillingPeriod);
    };

    const handleLoadBillings = (period) => {
        setLoading(true);
        setPayments([]);

        if (period === undefined) {
            period = billingPeriod;
        }

        let url = "";
        if (period === null) {
            url = "institutions/" + currentUser.institution.id + "/billings";
        } else {
            url =
                "institutions/" +
                currentUser.institution.id +
                "/billings/" +
                period.year() +
                "/" +
                (period.month() + 1);
        }

        AxiosInstance({
            url: url,
            method: "GET",
        })
            .then((response) => {
                let paymentsTemp = response.data.billings.flatMap((b) => {
                    if (!b.payments) return [];

                    return b.payments.map((p) => {
                        let tPayment = { ...b };
                        delete tPayment.payments;
                        tPayment.id = p.id;
                        tPayment.payment = p;

                        return tPayment;
                    });
                });

                setPayments(paymentsTemp);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                console.error("Error fetching billings:", error);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return (
        <Dashboard contentClass={"brands"}>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnComponent={
                    <>
                        <Button className={"btn-gray"} onClick={() => openModal({ period: billingPeriod }, "fees")}>
                            {t("Additional fees", { ns: "button" })}
                        </Button>
                        <Button className={"btn-primary"} onClick={() => openModal({ period: billingPeriod }, "bill")}>
                            {t("Billing current month", { ns: "button" })}
                        </Button>
                    </>
                }
            >
                <IoWalletOutline size={"1.5rem"} />
                {t("Billings", { ns: "common" })}
                <span className="flex items-center text-lg">
                    <Button className="btn-sm !px-2" onClick={() => handleChangePeriod("prev")} disabled={isLoading}>
                        <IoChevronBackOutline size={"1.25rem"} />
                    </Button>
                    <Button className="btn-sm !px-2" onClick={() => handleChangePeriod("next")} disabled={isLoading}>
                        <IoChevronForwardOutline size={"1.25rem"} />
                    </Button>
                    {billingPeriod && billingPeriod.format("MMMM YYYY")}
                </span>
            </ContentHeading>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <DataGrid rows={payments} columns={columns} enableSearch={true} getRowHeight={() => "auto"} />
            )}

            {modal.show && modal.data && modal.type === "bill" && (
                <ModalBillingCurrentMonth data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "fees" && (
                <ModalBillingAdditionalFee data={modal.data} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "refundsPayment" && (
                <ModalRefundsPayment orderId={modal.data} closeModal={handleModalClose} />
            )}
        </Dashboard>
    );
};
