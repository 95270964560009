import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    IoAccessibilityOutline,
    IoCalendarOutline,
    IoPeopleOutline,
    IoTimeOutline,
    IoTrashBinOutline,
} from "react-icons/io5";

import { DisplayDateTime, DisplayDayTime } from "utils/DisplayDate";
import { Badge, ContentHeading, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import ModalEnrollmentTrainer from "./ModalEnrollmentTrainer";
import ModalEnrollmentChangeDate from "./ModalEnrollmentChangeDate";
import { EnrollmentStatus } from "utils/EnrollmentStatus";
import ModalEnrollmentChangePaymentTerm from "./ModalEnrollmentChangePaymentTerm";
import ModalEnrollmentChangeKidRemove from "./ModalEnrollmentChangeKidRemove";

function EnrollmentList({ enrollments, handleLoadData }) {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ show: false, data: null });

    const columns = [
        {
            field: "activity",
            headerName: t("Activities", { ns: "common" }),
            width: 320,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 180,
            sortable: false,
            valueFormatter: (value) => `${t(value, { ns: "lexicons" })}`,
            valueGetter: (value, row) => {
                value = EnrollmentStatus(value);
                return `${value.name}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.status.color}>{t(params.row.status.name, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "institution",
            headerName: t("Institution", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
        },
        {
            field: "trainers",
            headerName: t("Trainers", { ns: "common" }),
            width: 240,
            valueGetter: (value, row) => {
                let trainers = row.date
                    .map((d) => {
                        return d.trainers
                            .map((t) => {
                                return t.fullName;
                            })
                            .join(", ");
                    })
                    .join(", ");

                return trainers ? trainers : "---";
            },
        },
        {
            field: "date",
            headerName: t("Date of activity", { ns: "common" }),
            minWidth: 180,
            flex: 1,
            sortable: false,
            valueGetter: (value, row) => {
                if (row.activity.period.value === "SINGLE") {
                    return `${row.date.map((d) => d) || ""}`;
                } else {
                    return `${row.date.map((d) => t(d.day.name, { ns: "lexicons" }) + " - " + d.time) || ""}`;
                }
            },
            renderCell: (params) => {
                return params.row.activity.period.value === "SINGLE" ? (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDateTime date={d.day} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                ) : (
                    <>
                        {params.row.date
                            .map((d) => {
                                return <DisplayDayTime day={d.day.name} time={d.time} />;
                            })
                            .reduce((prev, curr) => [prev, ", ", curr])}
                    </>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            disableExport: true,
            width: 120,
            renderCell: (params) => {
                let options = [];

                params.row.active &&
                    options.push(
                        {
                            text: t("Trainers", { ns: "button" }),
                            icon: <IoPeopleOutline size={"1.25em"} />,
                            menuItemProps: {
                                onClick: () => openModal(params.row, "updatedTrainers"),
                            },
                        },
                        {
                            text: t("Change the date of activity", { ns: "button" }),
                            icon: <IoCalendarOutline size={"1.25em"} />,
                            menuItemProps: {
                                onClick: () => openModal(params.row, "changeDate"),
                            },
                        },
                        {
                            text: t("Change the payment term", { ns: "button" }),
                            icon: <IoTimeOutline size={"1.25em"} />,
                            menuItemProps: {
                                onClick: () => openModal(params.row, "changePaymentTerm"),
                            },
                        }
                    );

                params.row.active &&
                    params.row.whoPay.value === "PAY_PARENT" &&
                    options.push({
                        text: t("Change the settings of kid remove", { ns: "button" }),
                        icon: <IoTrashBinOutline size={"1.25em"} />,
                        menuItemProps: {
                            onClick: () => openModal(params.row, "changeKidRemove"),
                        },
                    });

                return <OptionMenu options={options} />;
            },
        },
    ];

    const openModal = (enrollment, type = "view") => {
        setModal({ show: true, data: enrollment, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoAccessibilityOutline size={"1.5rem"} />
                {t("Enrollments", { ns: "common" })}
            </ContentHeading>

            <DataGrid rows={enrollments} columns={columns} enableSearch={true} fileName={"ProwadzoneZajęcia"} />

            {modal.show && modal.data && modal.type === "updatedTrainers" && (
                <ModalEnrollmentTrainer
                    data={modal.data}
                    closeModal={handleModalClose}
                    handleLoadData={handleLoadData}
                />
            )}

            {modal.show && modal.data && modal.type === "changeDate" && (
                <ModalEnrollmentChangeDate
                    data={modal.data}
                    closeModal={handleModalClose}
                    handleLoadData={handleLoadData}
                />
            )}

            {modal.show && modal.data && modal.type === "changePaymentTerm" && (
                <ModalEnrollmentChangePaymentTerm
                    data={modal.data}
                    closeModal={handleModalClose}
                    handleLoadData={handleLoadData}
                />
            )}

            {modal.show && modal.data && modal.type === "changeKidRemove" && (
                <ModalEnrollmentChangeKidRemove
                    data={modal.data}
                    closeModal={handleModalClose}
                    handleLoadData={handleLoadData}
                />
            )}
        </>
    );
}

export default EnrollmentList;
