import React from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@mui/material";
import { FormField } from "./FormField";

export const SelectField = ({
    label,
    fieldClassName,
    field: { name, value },
    form,
    required,
    options,
    isMulti = false,
    validation,
    handleBlur,
    getOptionLabel,
    ...props
}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        form.setFieldValue(name, typeof value === "object" ? event.target : event.target.value);
    };

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <Select
                size="small"
                onBlur={handleBlur}
                key={name}
                value={value ? (typeof value === "object" ? value.value : value) : ""}
                name={name}
                id={name}
                onChange={handleChange}
                {...props}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={typeof option === "object" ? option.value : option}>
                        {getOptionLabel ? (
                            <>{getOptionLabel(option)}</>
                        ) : (
                            t(typeof option === "object" ? option.name : option, { ns: "lexicons" })
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormField>
    );
};
