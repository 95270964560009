import dayjs from "dayjs";

const today = dayjs();

export function BillingStatus(status, dueDate, active) {
    let color = "";
    let title = "";
    dueDate = dayjs(dueDate);

    if (active === false) {
        color = "#313333";
        title = "Not active";
    } else if (status === "FAILED") {
        color = "#dc2626";
        title = "Failed";
    } else if (dueDate > today || status === "COMPLETED") {
        color = "#166534";
        title = "Paid";
    } else if (dueDate < today) {
        color = "#dc2626";
        title = "Not paid";
    }

    return {
        color: color,
        title: title,
    };
}
