import React from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@mui/material";
import { FormField } from "./FormField";

export const YesNoField = ({
    label,
    fieldClassName,
    field: { name, value },
    form,
    required,
    options = [false, true],
    isMulti = false,
    validation,
    handleBlur,
    ...props
}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        form.setFieldValue(name, event.target.value);
    };

    return (
        <FormField id={name} label={label} required={required} className={fieldClassName} formProps={form}>
            <Select size="small" key={name} value={value} name={name} id={name} onChange={handleChange}>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {t(option, { ns: "lexicons" })}
                    </MenuItem>
                ))}
            </Select>
        </FormField>
    );
};
